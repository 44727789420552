import React from "react";
import {Link} from "react-router-dom";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const FeaturedCard = ({item: {id, title, menu, cover, location, rank}}) => {
  return (
    <Link to={`/interior/restaurant/${id}`} style={{textDecoration: "none"}}>
      <Card
        sx={{
          maxWidth: {xs: 300, md: 400},
          width: "100%",
          position: "relative",
          boxShadow: 0,
          borderRadius: 0,
          my: 1,
          mx: "auto",
          border: "1px solid  #b0b0b0",
          ":hover": {
            boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 20px",
            transition: "100ms ease-in",
          },
        }}
      >
        {/* Image with rating */}
        <Box sx={{position: "relative"}}>
          <CardMedia component="img" height="240" image={cover} alt={title} />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              zIndex: 0,
            }}
          />
          <Chip
            label={rank}
            color="error"
            sx={{
              borderRadius: 0.2,
              position: "absolute",
              top: 8,
              right: 8,
              color: "white",
              fontWeight: "bold",
              backgroundColor: "rgba(255, 0, 0, 0.8)",
            }}
          />

          <Typography
            variant="h6"
            textTransform="capitalize"
            sx={{position: "absolute", bottom: 15, left: 12, color: "#fff"}}
          >
            {title}
          </Typography>
        </Box>

        {/* Card Content */}
        <CardContent>
          {/* Cuisine Type */}
          <Box display="flex" alignItems="center" sx={{mt: 1, color: "gray"}}>
            <RestaurantIcon fontSize="small" sx={{mr: 0.5}} />
            <Typography variant="body1" textTransform="capitalize">
              {menu}
            </Typography>
          </Box>

          {/* Location */}
          <Box display="flex" alignItems="center" sx={{color: "gray"}}>
            <LocationOnIcon fontSize="small" sx={{mr: 0.5}} />
            <Typography variant="body1" textTransform="capitalize">
              {location}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export default FeaturedCard;
