import React from "react";
import {Link} from "react-router-dom";
import {Box, CardMedia, Paper, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {COLORS as color} from "constants/Colors";

const PhotoGallery = ({photos, extraCount}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderColor: color.border.info,
        width: "100%",
        p: 2,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{color: color.text.textPrimary}}
      >
        Photos
      </Typography>
      <Grid container spacing={1}>
        {photos.map(({photo}, index) => (
          <Grid size={{xs: 6, sm: 3}} key={index} sx={{position: "relative"}}>
            <CardMedia
              component="img"
              height={140}
              image={photo}
              alt={`Photo ${index + 1}`}
            />
            {index === 3 && (
              <Link to="/">
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: 24,
                    fontWeight: "bold",
                  }}
                >
                  +{extraCount}
                </Box>
              </Link>
            )}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default PhotoGallery;
