import {useState} from "react";
import {Formik, Form} from "formik";
import {Modal, Box, Typography, Button, IconButton, Link} from "@mui/material";
import Grid from "@mui/material/Grid2";
import FacebookIcon from "@mui/icons-material/Facebook";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";

import TextField from "components/Ui/InputField";
import {validationSchema} from "./schema";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: {xs: 300, sm: 400},
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 2,
};

export default function LoginModal({open, handleClose}) {
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = (values) => {};

  const handleChange = (e) => {
    const {value, name} = e.target;
    setInitialValues((prevState) => ({...prevState, [name]: value}));
  };

  const {email, password} = initialValues;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box display="flex" width="100%" alignItems="center">
          <Typography variant="h6" component="h2" textAlign="center" flex={12}>
            Log in to Degusta
          </Typography>
          <IconButton onClick={handleClose} sx={{flex: 1}}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FacebookIcon />}
          sx={{width: "100%", backgroundColor: "#3b5998"}}
        >
          Login with Facebook
        </Button>
        <Typography variant="text" textAlign="center">
          We will never publish anything without asking you.
        </Typography>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={3} alignItems="center">
              <Grid size={{xs: 12}}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email address"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  icon={<EmailIcon />}
                />
              </Grid>
              <Grid size={{xs: 12}}>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  icon={<LockIcon />}
                />
              </Grid>
              <Grid size={{xs: 12}}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "100%",
                    backgroundColor: "#ff0000",
                    color: "#fff",
                  }}
                >
                  Enter
                </Button>
              </Grid>
              <Grid size={{xs: 12}}>
                <Link
                  href="#"
                  underline="none"
                  sx={{
                    mt: 1,
                    color: "gray",
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Forgot your password?
                </Link>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Box>
    </Modal>
  );
}
