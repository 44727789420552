import React, {useState} from "react";
import {Box, Typography, Button} from "@mui/material";
import Rating from "@mui/material/Rating";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CardLayout from "components/Cards/CardLayout";
import {COLORS as color} from "constants/Colors";

const RatingPoints = ({points, stars}) => {
  const [value, setValue] = useState(stars);

  const handleRate = () => {
    if (value > 0) {
      // onRate(value);
      setValue(0);
    }
  };

  return (
    <CardLayout
      title={
        <Typography variant="h6" sx={{color: color.text.textPrimary}}>
          Rate the restaurant get <strong>{points} points</strong>
        </Typography>
      }
      children={
        <Box sx={{display: "flex", alignItems: "center", gap: 2, mb: 2}}>
          <Typography variant="h6" sx={{color: color.text.textPrimary}}>
            Meal
          </Typography>
          <Rating
            name="meal-rating"
            size="large"
            precision={0.5}
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
          />
          <Button
            startIcon={
              <PlayArrowIcon
                sx={{
                  position: "absolute",
                  left: "-18px",
                  top: "3px",
                  transform: "rotate(64deg)",
                  color: color.blue,
                  fontSize: "2.3rem !important",
                }}
              />
            }
            variant="contained"
            onClick={handleRate}
            sx={{
              fontSize: {
                xs: "10px",
                sm: "initial",
                backgroundColor: color.blue,
                position: "relative",
                textTransform: "capitalize",
              },
            }}
          >
            Click to rate
          </Button>
        </Box>
      }
    />
  );
};

export default RatingPoints;
