import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import {Link} from "react-router-dom";

const PopularCard = ({item: {title, id, menu, cover, location, rank}}) => {
  return (
    <Link to={`/interior/restaurant/${id}`} style={{textDecoration: "none"}}>
      <Card
        sx={{
          maxWidth: {xs: 300, md: 400},
          position: "relative",
          boxShadow: 0,
          borderRadius: 0,
          borderBottom: 1,
          borderColor: "#e0e0e0",
          my: 1,
          mx: "auto",
          ":hover": {
            borderColor: "#B91F17",
            transition: "100ms ease-in",
          },
        }}
      >
        {/* Image with rating */}
        <Box sx={{position: "relative"}}>
          <CardMedia component="img" height="240" image={cover} alt={title} />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              zIndex: 0,
            }}
          />
          <Chip
            label={rank}
            color="error"
            sx={{
              borderRadius: 0.2,
              position: "absolute",
              top: 8,
              right: 8,
              color: "white",
              fontWeight: "bold",
              backgroundColor: "rgba(255, 0, 0, 0.8)",
            }}
          />
        </Box>

        {/* Card Content */}
        <CardContent sx={{px: 0}}>
          {/* Cuisine Type */}

          <Typography
            variant="h6"
            textTransform="capitalize"
            fontWeight={600}
            sx={{color: "#e8272a"}}
          >
            {menu}
          </Typography>

          {/* Location */}

          <Typography
            variant="body1"
            textTransform="capitalize"
            sx={{color: "gray"}}
          >
            {location}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default PopularCard;
