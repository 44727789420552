import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
  Chip,
  CardActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import StarIcon from "@mui/icons-material/Star";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {COLORS as color} from "constants/Colors";
import {Link} from "react-router-dom";

const textStyle = {
  color: color.mutedGray,
  fontSize: {xs: "0.875rem", lg: "1rem"},
};
const ratingStyle = {
  color: color.mediumGray,
  fontSize: {xs: "0.875rem", lg: "1.09rem"},
};
const RestaurantCard = ({image, id}) => {
  return (
    <Card
      sx={{
        maxWidth: "100%",
        boxShadow: 0,
        borderRadius: 0,
        borderBottom: 1,
        borderColor: color.offWhite,
        px: 0,
        py: 1.5,
        pb: 2.5,
      }}
    >
      <Grid container>
        {/* Left Section: Image */}
        <Grid size={{xs: 12, sm: 2.5}} position="relative">
          <Link
            to={`/interior/restaurant/${id}`}
            style={{textDecoration: "none"}}
          >
            <CardMedia
              component="img"
              image={image}
              sx={{height: {xs: 250, sm: 120}}}
              alt="Restaurant Image"
            />
          </Link>
          <Chip
            icon={
              <StarIcon
                style={{color: color.text.secondary, fontSize: "1.2rem"}}
              />
            }
            label="Destacado"
            sx={{
              position: "absolute",
              top: 8,
              left: 7,
              backgroundColor: color.orange,
              color: color.text.secondary,
              fontWeight: "bold",
              height: 25,
              borderRadius: 4,
              display: {xs: "none", lg: "inline-flex"},
              alignItems: "center",
            }}
          />
        </Grid>

        {/* Right Section: Content */}
        <Grid size={{xs: 12, sm: 9.5}}>
          <CardContent
            sx={{
              pb: 0,
              pt: {xs: 1, sm: 0},
              pl: {xs: 0, sm: 2},
              pr: 0,
              "&.MuiCardContent-root:last-child": {
                pb: 0.5,
              },
            }}
          >
            <Box
              sx={{display: "flex", justifyContent: "space-between", mb: 0.5}}
            >
              <Box>
                <Link
                  to={`/interior/restaurant/${id}`}
                  style={{textDecoration: "none"}}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: color.black,
                      ":hover": {
                        color: color.text.error,
                      },
                    }}
                  >
                    Austin Mama's (Boquete)
                  </Typography>
                </Link>
                <Typography variant="body2" sx={textStyle}>
                  Americana
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{color: color.darkBlue, textAlign: "right"}}
                >
                  $22
                </Typography>
                <Typography variant="body2" sx={{color: color.mediumGray}}>
                  27 votes
                </Typography>
              </Box>
            </Box>

            <Typography variant="body2" sx={textStyle}>
              Volcancito Road, hotel Los Abetos - Boquete - Interior
            </Typography>
            <Box mt={1}>
              <Grid container spacing={0}>
                <Grid size={{xs: 4}}>
                  <Typography variant="body2" sx={ratingStyle}>
                    Food{" "}
                    <Typography
                      fontWeight={600}
                      component="span"
                      sx={{color: color.black}}
                    >
                      4.5{" "}
                    </Typography>
                    <Typography component="span" variant="body2">
                      /5
                    </Typography>
                  </Typography>
                </Grid>
                <Grid size={{xs: 4}}>
                  <Typography variant="body2" sx={ratingStyle}>
                    Service{" "}
                    <Typography
                      fontWeight={600}
                      component="span"
                      sx={{color: color.black}}
                    >
                      4.6{" "}
                    </Typography>
                    <Typography component="span" variant="body2">
                      /5
                    </Typography>
                  </Typography>
                </Grid>
                <Grid size={{xs: 4}}>
                  <Typography
                    variant="body2"
                    sx={ratingStyle}
                    style={{textAlign: "right"}}
                  >
                    Environment{" "}
                    <Typography
                      fontWeight={600}
                      component="span"
                      sx={{color: color.black}}
                    >
                      4.8{" "}
                    </Typography>
                    <Typography component="span" variant="body2">
                      /5
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Grid>
        <Grid size={{xs: 12}}>
          <CardActions sx={{p: 0, mt: {xs: 0, lg: 1}}}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                py: 1,
                textTransform: "none",
                fontWeight: 500,
                width: "100%",
                border: 1,
                borderColor: color.border.primary,
                fontSize: "1.25rem",
              }}
            >
              <CalendarMonthOutlinedIcon sx={{fontSize: "1.25rem", mx: 1}} />{" "}
              Book Online{" "}
              <Chip
                label="20% OFF"
                sx={{
                  backgroundColor: "#436fe8",
                  color: color.text.secondary,
                  fontWeight: 600,
                  height: 25,
                  fontSize: "1rem",
                  borderRadius: 1,
                  ml: 1,
                  "& .MuiChip-label": {
                    px: 1,
                  },
                  display: {xs: "none", sm: "block"},
                }}
              />
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};

export default RestaurantCard;
