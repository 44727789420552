import React, {useState} from "react";
import {
  Box,
  Card,
  Typography,
  Tabs,
  Tab,
  CardMedia,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Schedule from "./Schedule";
import Map from "components/Map";
import {COLORS as color} from "constants/Colors";

import American_Express_logo from "assets/images/American_Express_logo.png";
import Mastercard from "assets/images/Mastercard-logo.png";
import visa_payment from "assets/images/visa-payment-card1873.jpg";

const InfoCard = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Card
      sx={{
        border: `1px solid ${color.border.info}`,
        boxShadow: 0,
        borderRadius: 1,
      }}
    >
      {/* Tabs */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          borderBottom: `1px solid ${color.lightGray1}`,
          mb: 1,
          "& .MuiTab-root": {
            color: color.lightGray3,
          },
          "& .Mui-selected": {
            color: color.button.error + "!important",
            borderRight: 1,
            borderColor: color.border.info,
          },
          "& .MuiTabs-indicator": {
            backgroundColor: color.button.error,
          },
        }}
      >
        <Tab label="Information" />
      </Tabs>

      {/* Tab Content */}
      {activeTab === 0 && (
        <Grid container spacing={2} sx={{p: 2}}>
          {/* Left Section */}
          <Grid size={{xs: 12, sm: 5}}>
            <Typography
              variant="body1"
              sx={{fontWeight: "bold", color: color.text.textPrimary}}
            >
              Address:
            </Typography>
            <Typography variant="body2" sx={{color: color.lightGray3}}>
              Terraces of Coronado - Coronado - Interior
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#e0f7fa",
                height: 100,
                mt: 2,
                borderRadius: 1,
              }}
            >
              <Map />
            </Box>
            <Typography
              variant="body1"
              sx={{fontWeight: "bold", mt: 2, color: color.text.textPrimary}}
            >
              Schedule:
            </Typography>

            <Schedule />
          </Grid>
          <Grid size={{xs: 12, sm: 1}} sx={{width: "0 !important"}}>
            {" "}
            <Divider orientation="vertical" />{" "}
          </Grid>
          {/* Right Section */}
          <Grid size={{xs: 12, sm: 6}}>
            <Typography
              variant="body1"
              sx={{fontWeight: "bold", color: color.text.textPrimary}}
            >
              Payment methods:
            </Typography>
            <Box sx={{display: "flex", gap: 2, mt: 1}}>
              <CardMedia
                component="img"
                height="30"
                image={Mastercard}
                alt="Mastercard"
                sx={{width: "auto"}}
              />
              <CardMedia
                component="img"
                height="30"
                image={American_Express_logo}
                alt="American Express"
                sx={{width: "auto"}}
              />
              <CardMedia
                component="img"
                height="30"
                image={visa_payment}
                alt="Visa"
                sx={{width: "auto"}}
              />
            </Box>
            <Divider sx={{mt: 3}} />
            <Typography
              variant="body1"
              sx={{fontWeight: "bold", mt: 2, color: color.text.textPrimary}}
            >
              Services:
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                color: color.lightGray3,
                display: "flex",
                alignItems: "center",
                gap: 0.3,
              }}
            >
              <Typography
                component="span"
                sx={{color: color.lightBlue, fontSize: "2rem"}}
              >
                •
              </Typography>{" "}
              Parking lots
            </Typography>
            <Divider />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{mt: 2, color: color.lightGray3, cursor: "pointer"}}
            >
              Is the venue information incorrect? Please let us know about the
              error.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default InfoCard;
