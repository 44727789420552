import React from "react";
import {Typography, Box, CardMedia} from "@mui/material";
import Grid from "@mui/material/Grid2";

import coffee1 from "assets/images/coffee1.jpg";
import coffee2 from "assets/images/coffee2.jpg";
import pizza from "assets/images/pizza.jpg";
import homeBg from "assets/images/homeBg.jpg";

const restaurants = [
  {
    name: "Al Basha (Uruguay Street)",
    cuisine: "Lebanese, International",
    location: "Banking Area",
    price: "$25",
    food: 4.7,
    service: 4.6,
    environment: 4.6,
    votes: 2525,
  },
  {
    name: "Ato Fusion Cuisine",
    cuisine: "Japanese, Asian, Fusion",
    location: "San Francisco",
    price: "$29",
    food: 4.6,
    service: 4.6,
    environment: 4.7,
    votes: 4012,
  },
];

const Visited = () => {
  return (
    <Box sx={{padding: "16px"}}>
      <Typography variant="h5" sx={{marginBottom: "16px"}}>
        Restaurants Visited
      </Typography>
      {restaurants.map((restaurant, index) => (
        <Box
          key={index}
          sx={{
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <Grid container spacing={2}>
            <Grid size={{xs: 12, sm: 2}}>
              {/* Placeholder for image */}
              <CardMedia
                component="img"
                height="100"
                image={coffee1}
                alt={coffee1}
              />
            </Grid>
            <Grid size={{xs: 12, sm: 8}}>
              <Typography variant="h6" sx={{fontWeight: "bold"}}>
                {restaurant.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {restaurant.cuisine}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {restaurant.location}
              </Typography>
              <Grid container spacing={2} sx={{marginTop: "8px"}}>
                <Grid item>
                  <Typography variant="body2">
                    <strong>Food:</strong> {restaurant.food} /5
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <strong>Service:</strong> {restaurant.service} /5
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <strong>Environment:</strong> {restaurant.environment} /5
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {restaurant.votes} Votes
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              size={{xs: 12, sm: 2}}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{fontWeight: "bold", color: "#1976d2"}}
              >
                {restaurant.price}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default Visited;
