import React, {useState} from "react";
import {Box, Button, Chip, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PeopleIcon from "@mui/icons-material/People";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SelectField from "components/Ui/SelectField";
import {COLORS as color} from "constants/Colors";
import moment from "moment";
import {Formik} from "formik";
import {Form} from "react-router-dom";
import {validationSchema} from "./schema";

const selectStyle = {
  border: `1px solid ${color.lightGray4}`,
  color: color.text.textPrimary,
  fontWeight: 600,
  "& .MuiSelect-icon": {
    transition: "none",
    transform: "unset",
    mr: 1,
    color: color.text.textPrimary,
  },
  "& .MuiSelect-select": {
    justifyContent: "space-evenly",
    color: `${color.text.textPrimary} !important`,
  },
};
const ReservationComponent = () => {
  const today = moment();

  const [initialValues, setInitialValues] = useState({
    numOfPeople: "2 People",
    reservationDate: "monday 25th nov",
    reservationTime: "1:00 pm",
  });

  // Generate a list of dates (e.g., next 7 days)
  const dates = Array.from({length: 7}, (_, i) => today.clone().add(i, "days"));

  // Custom function to format dates in the desired format
  const formatDate = (date) => date.format("dddd Do MMM").toLowerCase();

  const generateTimeOptions = (start, end, interval) => {
    const times = [];
    let current = moment(start, "HH:mm");
    const endTime = moment(end, "HH:mm");

    while (current <= endTime) {
      const formattedTime = current.format("h:mm a");
      times.push({
        label: (
          <>
            <Chip
              label="2x1"
              sx={{
                backgroundColor: color.text.error,
                color: color.text.secondary,
                fontWeight: 600,
                height: 25,
                fontSize: "1rem",
                borderRadius: 1,
                mr: 1,
                "& .MuiChip-label": {
                  px: 2,
                },
                display: {xs: "none", sm: "block"},
              }}
            />{" "}
            {formattedTime}
          </>
        ),
        value: current.format("HH:mm"),
      });
      current = current.add(interval, "minutes");
    }

    return times;
  };

  const timeOptions = generateTimeOptions("12:00", "14:00", 30);

  const handleSubmit = (values) => {};

  const handleChange = (e) => {
    const {value, name} = e.target;
    setInitialValues((prevState) => ({...prevState, [name]: value}));
  };

  const {numOfPeople, reservationDate, reservationTime} = initialValues;

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: 1,
        overflow: "hidden",
        backgroundColor: "#f8f8f8",
        pb: 0.5,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          padding: 1,
          backgroundColor: "#e0e0e0",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: color.text.textPrimary,
            display: "flex",
            alignItems: {xs: "start", sm: "center"},
          }}
        >
          <CalendarMonthOutlinedIcon sx={{marginRight: 0.5}} />
          Make your reservation online for FREE!
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          {/* Reservation Inputs */}
          <Grid container spacing={1} sx={{padding: 1, alignItems: "center"}}>
            {/* People Selector */}
            <Grid size={{xs: 12, sm: 4}}>
              <Box sx={{display: "flex", alignItems: "center"}}>
                <SelectField
                  value={numOfPeople}
                  name="numOfPeople"
                  onChange={handleChange}
                  IconComponent={PeopleIcon}
                  style={selectStyle}
                  fullWidth
                  size="small"
                  options={[2, 3, 4, 5].map((num) => ({
                    label: `${num} People`,
                    value: `${num} People`,
                  }))}
                />
              </Box>
            </Grid>

            {/* Date Selector */}
            <Grid size={{xs: 12, sm: 4}}>
              <SelectField
                value={reservationDate}
                name="reservationDate"
                onChange={handleChange}
                IconComponent={CalendarMonthOutlinedIcon}
                style={selectStyle}
                fullWidth
                size="small"
                options={dates.map((date) => ({
                  value: formatDate(date),
                  label: formatDate(date),
                }))}
              />
            </Grid>

            {/* Time Selector */}
            <Grid size={{xs: 12, sm: 4}}>
              <SelectField
                value={reservationTime}
                name="reservationTime"
                defaultValue={reservationTime}
                onChange={handleChange}
                style={selectStyle}
                IconComponent={AccessTimeIcon}
                fullWidth
                size="small"
                options={timeOptions.map((option) => ({
                  value: option.value,
                  label: option.label,
                }))}
              />
            </Grid>
          </Grid>

          {/* Reserve Button */}
          <Box sx={{padding: 1, textAlign: "center"}}>
            <Button
              variant="contained"
              color="primary"
              sx={{textTransform: "capitalize", fontSize: "1.2rem"}}
              fullWidth
            >
              Reserve
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default ReservationComponent;
