import React, {useState} from "react";
import {Box, Card, Divider, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";

import RestaurantInfoCard from "pages/Interior/RestaurantDetails/components/RestaurantInfo";
import RestaurantRating from "pages/Interior/RestaurantDetails/components/RestaurantRating";
import InfoCard from "pages/Interior/RestaurantDetails/components/InfoCard";
import PhotoGallery from "pages/Interior/RestaurantDetails/components/PhotoGallery";
import RecommendedDish from "pages/Interior/RestaurantDetails/components/RecommendedDish";
import RatingPoints from "pages/Interior/RestaurantDetails/components/RatingPoints";
import Comments from "pages/Interior/RestaurantDetails/components/Comments";
import RecommendationCard from "pages/Interior/RestaurantDetails/components/RecommendationCard";
import BusinessClaim from "pages/Interior/RestaurantDetails/components/BusinessClaim";
import Reservation from "pages/Interior/RestaurantDetails/components/Reservation";
import Tabs from "components/Tabs";

import ScrollToTop from "components/Common/ScrollToTop";
import AdvancedFilters from "components/Common/AdvancedFilters";

import {CONSTANTS as c} from "constants/AppConstants";
// import {COLORS as color} from "constants/Colors";

import coffee1 from "assets/images/coffee1.jpg";
import coffee2 from "assets/images/coffee2.jpg";
import pizza from "assets/images/pizza.jpg";
import homeBg from "assets/images/homeBg.jpg";

const contentStyles = {
  maxWidth: c.MAX_WIDTH,
  mx: "auto",
  width: "90%",
  my: 4,
  mb: 6,
};

const ratings = [
  {label: "Meal", value: "4.4 ", total: 5},
  {label: "Service", value: "4.5 ", total: 5},
  {label: "Atmosphere", value: "4.4 ", total: 5},
  {label: "Votes", value: "17"},
];

const recommendations = [
  {
    heading: "Other recommendations",
    title: "Lona Rosa",
    subtitle: "Crowned",
    rating: 3.8,
    image: pizza,
    isButton: true,
  },
  {
    heading: "",
    title: "Luna Rossa",
    subtitle: "Crowned",
    rating: 4.8,
    image: coffee1,
    isButton: false,
  },
  {
    heading: "",
    title: "Pretelt Gourmet Meats (David)",
    subtitle: "David",
    rating: 3.5,
    image: coffee2,
    isButton: false,
  },
];

const photos = [
  {photo: coffee1},
  {photo: coffee2},
  {photo: pizza},
  {photo: homeBg},
];

const InteriorDetail = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabItems = ["Most recent", "Most influential", `Filtered(0)`];

  const id = "eyJmaWx0ZXJzIjp7ImRpc2NvdW50cyI6dHJ1ZX0sInNvcnQiOiJkaXNjb3VudCJ9";
  return (
    <Box>
      {/* Scrolled the page when the component mounted. */}
      <ScrollToTop />
      {/* Start ,advanced search  and discount buttons */}
      <AdvancedFilters id={id} />
      <Box sx={contentStyles}>
        <Grid
          container
          spacing={6}
          sx={{
            bgcolor: "background.paper",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <Grid size={{xs: 12, lg: 8}}>
            <Grid container spacing={2}>
              <Grid size={{xs: 12, lg: 12}}>
                <RestaurantInfoCard />
              </Grid>
              <Grid size={{xs: 12, lg: 12}}>
                <Reservation />
              </Grid>
              <Grid size={{xs: 12, lg: 12}}>
                <Grid container spacing={1}>
                  {ratings.map(({label, value, total}) => (
                    <Grid size={{xs: 6, sm: 3}}>
                      <RestaurantRating
                        label={label}
                        value={value}
                        total={total}
                        key={label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid size={{xs: 12, lg: 12}}>
                <InfoCard />
              </Grid>
              <Grid size={{xs: 12, lg: 12}}>
                <PhotoGallery photos={photos} extraCount={234} />
              </Grid>
              <Grid size={{xs: 12, lg: 12}}>
                <RecommendedDish />
              </Grid>
              <Grid size={{xs: 12, lg: 12}}>
                <RatingPoints points={22} stars={3.5} />
              </Grid>
              <Grid size={{xs: 12, lg: 12}}>
                <Tabs
                  selectedTab={selectedTab}
                  handleTabChange={handleTabChange}
                  tabItems={tabItems}
                  noOfComments={17}
                >
                  {Array.from({length: 3}).map((_, index) => (
                    <Comments key={index} selectedTab={selectedTab} />
                  ))}
                </Tabs>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs: 12, lg: 4}}>
            <Grid container spacing={1}>
              {recommendations?.map((item, index) => (
                <Grid size={{xs: 12, lg: 12}}>
                  <RecommendationCard
                    key={index}
                    heading={item.heading}
                    title={item.title}
                    subtitle={item.subtitle}
                    rating={item.rating}
                    image={item.image}
                    isButton={item.isButton}
                  />
                </Grid>
              ))}

              <Grid size={{xs: 12, lg: 12}}>
                <BusinessClaim />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InteriorDetail;
