import React, {useState, useEffect} from "react";
import {Box, Button, Divider, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import {Form, Formik} from "formik";

import SelectField from "components/Ui/SelectField";
import InputField from "components/Ui/InputField";
import {validationSchema} from "pages/Home/components/schema";
import {COLORS as color} from "constants/Colors";

import HomeBg from "assets/images/homeBg.jpg";
import {Link} from "react-router-dom";

const filtersStyles = {
  color: color.lightGray2,
  display: "inline-flex",
  alignItems: "center",
  ":hover": {
    opacity: 0.9,
  },
};

const Header = () => {
  const [scrollY, setScrollY] = useState(0);

  const [initialValues, setInitialValues] = useState({
    search: "",
    location: "inside",
  });

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  const handleSubmit = () => {};

  const handleLocationChange = (e) => {
    const {value, name} = e.target;
    setInitialValues((prevState) => ({...prevState, [name]: value}));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {location, search} = initialValues;

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${HomeBg})`,
        backgroundAttachment: "fixed",
        backgroundPosition: `center ${scrollY * 0.06}px`,
        backgroundSize: "cover",
        color: "white",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: color.overlay.info,
          zIndex: 0,
        }}
      />
      <Box sx={{zIndex: 1}}>
        <Typography
          variant="h5"
          fontWeight={500}
          sx={{fontSize: {xs: "1.5rem", sm: "2.125rem"}, px: 1, mb: 4}}
        >
          Discover the best restaurants in the Interior
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <Grid size={{xs: 12, sm: 0, md: 3}}>
                <Box
                  sx={{
                    display: {xs: "none", md: "flex"},
                    alignItems: "center",
                    backgroundColor: color.white,
                    borderRadius: 1,
                    p: 1.3,
                    mr: 2,
                    "& .MuiSvgIcon-root": {color: color.black},
                  }}
                >
                  <SelectField
                    value={location}
                    fullWidth
                    name="location"
                    onChange={handleLocationChange}
                    icon={<LocationOnIcon />}
                    options={[
                      {value: "inside", label: "Inside"},
                      {value: "panama", label: "Panama"},
                    ]}
                  ></SelectField>
                </Box>
              </Grid>
              <Grid size={{xs: 8, sm: 8, md: 7}}>
                <InputField
                  name="search"
                  value={search}
                  placeholder="Restaurant, type of cuisine, area..."
                  icon={<SearchIcon />}
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 6,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {border: "none"},
                      "&:hover fieldset": {border: "none"},
                      "&.Mui-focused fieldset": {border: "none"},
                      color: "black",
                    },
                  }}
                />
              </Grid>
              <Grid size={{xs: 3, sm: 2, md: 2}}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    p: 1,
                    height: "57px",
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 6,
                    borderBottomRightRadius: 6,
                    borderBottomLeftRadius: 0,
                    backgroundColor: color.button.primary,
                    color: color.text.secondary,
                    fontSize: {xs: "11px", sm: "0.875rem"},
                    "&:hover": {
                      backgroundColor: color.cherryRed,
                    },
                  }}
                >
                  Look for
                </Button>
              </Grid>
            </Grid>
            <Grid size={{xs: 12}}>
              <Box
                sx={{
                  display: {xs: "none", md: "flex"},
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Link
                  to="/interior/restaurant/1"
                  style={{textDecoration: "none"}}
                >
                  {" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={filtersStyles}
                  >
                    Advanced search
                  </Typography>
                </Link>
                <Divider
                  orientation="vertical"
                  sx={{height: 20, borderColor: color.lightGray3}}
                />
                <Link
                  to="/interior/restaurant/2"
                  style={{textDecoration: "none"}}
                >
                  {" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={filtersStyles}
                  >
                    User ranking
                  </Typography>{" "}
                </Link>
                <Divider
                  orientation="vertical"
                  sx={{height: 20, borderColor: color.lightGray3}}
                />
                <Link
                  to="/interior/add-restaurant"
                  style={{textDecoration: "none"}}
                >
                  {" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={filtersStyles}
                  >
                    Add Restaurant
                  </Typography>{" "}
                </Link>
              </Box>
            </Grid>
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};

export default Header;
