import React from "react";
import {Box, Typography, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: {xs: "100%", sm: "80%"},
          maxHeight: "100%",
          minHeight: "300px",
          backgroundColor: "#ff0000",
          display: "flex",
          flexDirection: "column",
          color: "#fff",
          justifyContent: "center",
          borderRadius: 2,
        }}
      >
        <Typography variant="h1" sx={{fontSize: "5rem", fontWeight: "bold"}}>
          404
        </Typography>
        <Typography variant="h6" sx={{marginBottom: "20px"}}>
          Oops! Page not found.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{
            backgroundColor: "#fff",
            color: "#ff0000",
            maxWidth: "300px",
            borderRadius: 2,
            p: 2,
            mx: "auto",
            "&:hover": {
              opacity: 0.9,
            },
          }}
        >
          Go to Home
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
