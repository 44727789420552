import React from "react";
import {Avatar, Box, Card, Typography} from "@mui/material";
import {COLORS as color} from "constants/Colors";

import homeBg from "assets/images/homeBg.jpg";
import pizza from "assets/images/pizza.jpg";

const UserProfileCard = () => {
  return (
    <Card
      sx={{
        textAlign: "center",
        padding: 2,
        backgroundColor: "#fff",
        borderRadius: 2,
      }}
    >
      {/* Profile Header */}
      <Box
        sx={{
          position: "relative",
          height: 200,
          backgroundImage: `url(${homeBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Avatar
          src={pizza}
          alt="Profile Picture"
          sx={{
            width: 80,
            height: 80,
            border: "4px solid #fff",
            position: "absolute",
            bottom: -40,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </Box>

      {/* Profile Details */}
      <Box sx={{marginTop: 5}}>
        <Typography variant="h6" sx={{fontWeight: 600}}>
          Enot A.
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{color: "#ff4757", fontWeight: 600, marginBottom: 1}}
        >
          Taster ★★★★☆
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{color: "#ff6b6b", fontWeight: 600}}
        >
          🏅 Foodie Member
        </Typography>

        {/* Points and Rankings */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant="body2" sx={{fontWeight: 600}}>
              2506
            </Typography>
            <Typography variant="caption" sx={{color: color.text.info}}>
              Points
            </Typography>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant="body2" sx={{fontWeight: 600}}>
              999+
            </Typography>
            <Typography
              component="span"
              variant="caption"
              sx={{color: color.text.info}}
            >
              Monthly ranking
            </Typography>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant="body2" sx={{fontWeight: 600}}>
              999+
            </Typography>
            <Typography
              component="span"
              variant="caption"
              sx={{color: color.text.info}}
            >
              Overall ranking
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* About Me Section */}
      <Box
        sx={{
          textAlign: "left",
          marginTop: 3,
          padding: 2,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{fontWeight: 600}}>
          About me
        </Typography>
        <Typography variant="body2" sx={{marginTop: 1}}>
          <strong>Member since:</strong> November 2016
        </Typography>
        <Typography variant="body2" sx={{marginTop: 1}}>
          <strong>Instagram:</strong> wine tasting
        </Typography>
        <Typography variant="body2" sx={{marginTop: 1}}>
          <strong>Twitter:</strong> @enotarauz
        </Typography>
      </Box>
    </Card>
  );
};

export default UserProfileCard;
