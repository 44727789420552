import {Box, Typography, Rating} from "@mui/material";

import {COLORS as color} from "constants/Colors";
import {useState} from "react";

const ratingStyles = {
  "& .MuiRating-iconFilled": {
    color: color.scarletRed,
  },
  ml: 1,
};

const FoodsRating = ({comment}) => {
  const [value, setValue] = useState(comment.mealRating);

  const handleRate = () => {
    if (value > 0) {
      setValue(0);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {xs: "row", sm: "column"},
        justifyContent: "center",
        flex: 1,
        gap: 0.2,
        mt: {xs: 0, sm: 2},
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{flexDirection: {xs: "column", sm: "row"}}}
      >
        <Typography variant="body2" sx={{color: color.lightGray3}}>
          Meal
        </Typography>
        <Rating
          size="small"
          sx={ratingStyles}
          name="meal-rating"
          value={value}
          precision={0.5}
          onChange={(event, newValue) => setValue(newValue)}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{flexDirection: {xs: "column", sm: "row"}}}
      >
        <Typography variant="body2" sx={{color: color.lightGray3}}>
          Service
        </Typography>
        <Rating
          size="small"
          sx={ratingStyles}
          precision={0.5}
          value={comment.serviceRating}
          onChange={(event, newValue) => setValue(newValue)}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{flexDirection: {xs: "column", sm: "row"}}}
      >
        <Typography variant="body2" sx={{color: color.lightGray3}}>
          Atmosphere
        </Typography>
        <Rating
          size="small"
          sx={ratingStyles}
          precision={0.5}
          value={comment.atmosphereRating}
          onChange={(event, newValue) => setValue(newValue)}
        />
      </Box>
    </Box>
  );
};

export default FoodsRating;
