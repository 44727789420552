export const containerStyle = {
  maxWidth: "100%",
  bgcolor: "background.paper",
  position: "absolute",
  top: {xs: "1.5rem", md: "-5.5rem"},
  mx: "auto",
  border: {xs: "0", md: "1px solid  #b0b0b0"},
  ":last-of-type": {
    borderRight: "none",
  },
};

export const tabItemStyle = {
  width: {xs: "100px", sm: "120px", md: "80px", lg: "100px"},
  minWidth: {xs: "100px", md: "80px", lg: "100px"},
  height: "80px",
  p: 2,
  borderTop: {xs: "1px solid #b0b0b0", md: 0},
  borderRight: {xs: "1px solid #b0b0b0", md: "1px solid #b0b0b0"},
  borderBottom: {xs: "1px solid #b0b0b0", md: 0},
  borderLeft: {xs: "1px solid #b0b0b0", md: 0},
  transition: "all 0.3s ease-out 0s",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  color: "#616161",
  "& .MuiSvgIcon-root": {
    fontSize: "2.5rem",
  },
  ":hover": {
    transform: "scaleY(1.1)",
    transition: "all 100ms ease-in",
    backgroundColor: "#f8f8f8",
    boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 20px",
  },
};

export const tabMainStyle = {
  display: "flex",
  justifyContent: "center",
  gap: {xs: "1rem", md: 0},
  flexWrap: {xs: "wrap", md: "nowrap"},
};
