import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  Card,
  FormControl,
  FormLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import {CONSTANTS as c} from "constants/AppConstants";
import {COLORS as color} from "constants/Colors";
import AdvancedFilters from "components/Common/AdvancedFilters";

const contentStyles = {
  maxWidth: c.MAX_WIDTH,
  mx: "auto",
  width: "70%",
  padding: 2,
  borderRadius: 2,
  mb: 6,
};

const headingStyle = {
  py: 2,
  "::after": {
    content: '""', // Ensure content is a valid empty string
    display: "inline-block",
    borderTopStyle: "solid",
    borderTopWidth: "2px", // Define the thickness of the border
    borderColor: `${color.dangerRed} !important`, // Apply your custom border color
    width: "5rem", // Set the width of the pseudo-element
  },
};

const AddRestaurantForm = () => {
  const id = "eyJmaWx0ZXJzIjp7ImRpc2NvdW50cyI6dHJ1ZX0sInNvcnQiOiJkaXNjb3VudCJ9";
  return (
    <Box>
      <AdvancedFilters id={id} />
      <Box sx={contentStyles}>
        <Grid container spacing={2}>
          <Grid size={{xs: 12, sm: 7}}>
            <Box sx={headingStyle}>
              <Typography
                variant="h4"
                fontWeight="bold"
                gutterBottom
                sx={{color: color.black}}
              >
                Add a restaurant
              </Typography>
            </Box>
          </Grid>
          <Grid size={{xs: 12, sm: 5}}>
            <Button
              variant="outlined"
              fullWidth
              size="large"
              sx={{
                float: "right",
                textTransform: "none",
                fontWeight: 500,
                fontSize: "1.25rem",
                mt: 2,
                ":hover": {
                  backgroundColor: color.darkBlue,
                  color: color.text.secondary,
                },
              }}
            >
              I own a restaurant
            </Button>
          </Grid>
        </Grid>

        <Card
          sx={{
            mt: 4,
            p: 4,
            border: `1px solid ${color.offWhite}`,
            boxShadow: 0,
          }}
        >
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{color: color.mediumGray}}
            >
              General information
            </Typography>

            <Grid container spacing={4}>
              <Grid size={{xs: 12, sm: 12}}>
                <FormControl sx={{width: "100%"}}>
                  <FormLabel htmlFor="RestaurantName" sx={{mb: 1}}>
                    <Typography component="span" fontWeight={600}>
                      {" "}
                      Name of the restaurant{" "}
                    </Typography>
                    <Typography component="span" sx={{color: "#bfbfbf"}}>
                      {" "}
                      (try to avoid special characters)
                    </Typography>
                  </FormLabel>
                  <TextField
                    // error={RestaurantNameError}
                    // helperText="(Try to avoid special characters)"
                    id="RestaurantName"
                    name="RestaurantName"
                    placeholder="Enter the name of the restaurant"
                    autoComplete="RestaurantName"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    // color={RestaurantNameError ? 'error' : 'primary'}
                  />
                </FormControl>
              </Grid>
              <Grid size={{xs: 12, sm: 12}}>
                <FormLabel htmlFor="city">
                  {" "}
                  <Typography component="span" fontWeight={600}>
                    City
                  </Typography>
                </FormLabel>
                <Select
                  sx={{mt: 1}}
                  displayEmpty
                  defaultValue=""
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="" disabled>
                    Choose the city
                  </MenuItem>
                  <MenuItem value="City1">City1</MenuItem>
                  <MenuItem value="City2">City2</MenuItem>
                  <MenuItem value="City3">City3</MenuItem>
                </Select>
              </Grid>
              <Grid size={{xs: 12, sm: 12}}>
                <FormControl sx={{width: "100%"}}>
                  <FormLabel htmlFor="address" sx={{mb: 1}}>
                    <Typography component="span" fontWeight={600}>
                      Address, additional information, etc.
                    </Typography>
                  </FormLabel>
                  <TextField
                    // error={emailError}
                    id="address"
                    name="address"
                    placeholder="Eg: Avenue, street, building, telephone number, etc."
                    autoComplete="address"
                    autoFocus
                    required
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    // color={emailError ? 'error' : 'primary'}
                  />
                </FormControl>
              </Grid>
              <Grid size={{xs: 12, sm: 12}}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "capitalize",
                    mt: 2,
                    backgroundColor: color.darkBlue,
                    fontSize: "1.2rem",
                  }}
                >
                  Send Restaurant
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default AddRestaurantForm;
