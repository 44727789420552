import React, {useRef} from "react";
import {Link} from "react-router-dom";
import {Box, Button, Divider, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import PopularCard from "components/Cards/PopularCard";

import {COLORS as color} from "constants/Colors";

const buttonStyle = {
  borderRadius: 0.2,
  color: color.text.secondary,
  fontWeight: "bold",
  backgroundColor: color.softWhite,
  "& .MuiButton-startIcon": {
    mr: 0,
    ml: 1,
    color: color.text.info,
    fontSize: "12px",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1rem",
  },
  "&.MuiButton-root": {
    minWidth: "20px",
  },
  ":hover": {
    backgroundColor: color.lightGray1,
  },
};

const ForCarnivores = ({data}) => {
  const swiperRef = useRef(null);

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Box width="100%">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/" style={{textDecoration: "none"}}>
          <Typography
            variant="h5"
            textTransform="capitalize"
            fontWeight={600}
            sx={{
              color: color.text.textPrimary,
              ":hover": {
                color: color.text.error,
              },
            }}
          >
            For Carnivores
          </Typography>
        </Link>
        <Box display="flex" justifyContent="space-between" gap="0.3rem">
          <Button
            startIcon={<ArrowBackIosIcon />}
            onClick={handlePrevSlide}
            color="error"
            sx={buttonStyle}
          />
          <Button
            startIcon={<ArrowForwardIosIcon />}
            color="error"
            onClick={handleNextSlide}
            sx={buttonStyle}
          />
        </Box>
      </Box>
      <Divider sx={{mt: 1, mb: 4}} />
      <Grid container spacing={1}>
        <Swiper
          ref={swiperRef}
          spaceBetween={10}
          slidesPerView={4}
          slidesPerGroup={4}
          loop={true}
          slideToClickedSlide={true}
          className="mySwiper"
          breakpoints={{
            // Define breakpoints
            0: {
              slidesPerView: 1, // Show 1 slides on small screens (xs)
              spaceBetween: 10, // Adjust space between slides for small screens
            },
            375: {
              slidesPerView: 2, // Show 2 slides on small screens (xs)
              spaceBetween: 10, // Adjust space between slides for small screens
            },
            600: {
              slidesPerView: 4, // Show 4 slides on medium-small screens
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 4, // Show 4 slides on large screens
              spaceBetween: 20,
            },
          }}
        >
          {data.map((item, index) => (
            <Grid
              size={{xs: 12}}
              key={`feature-restaurant-${index}`}
              overflow="hidden"
            >
              <SwiperSlide>
                <PopularCard item={item} />
              </SwiperSlide>
            </Grid>
          ))}
        </Swiper>
      </Grid>
    </Box>
  );
};

export default ForCarnivores;
