import {useState} from "react";
import {Modal, Box, Typography, Button, IconButton} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid2";
import {Formik, Form} from "formik";
import TextField from "components/Ui/InputField";
import {validationSchema} from "./schema";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: {xs: 320, sm: 400},
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 2,
  border: 0,
};

export default function SignupModal({handleClose, open}) {
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleSubmit = (values) => {};

  const handleChange = (e) => {
    const {value, name} = e.target;
    setInitialValues((prevState) => ({...prevState, [name]: value}));
  };

  const {firstName, lastName, email, password} = initialValues;

  return (
    <Modal open={open} onClose={handleClose} sx={{zIndex: 3000}}>
      <Box sx={style}>
        <Box display="flex" width="100%" alignItems="center">
          <Typography variant="h6" component="h2" textAlign="center" flex={12}>
            Create an account on Degusta
          </Typography>
          <IconButton onClick={handleClose} sx={{flex: 1}}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FacebookIcon />}
          sx={{
            width: "100%",
            backgroundColor: "#3b5998",
            textTransform: "capitalize",
          }}
        >
          Login with Facebook
        </Button>
        <Typography variant="text" textAlign="center">
          We will never publish anything without asking you.
        </Typography>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={2} alignItems="center">
              <Grid size={{xs: 12, sm: 6, md: 6}}>
                <TextField
                  fullWidth
                  label="Name"
                  name="firstName"
                  value={firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={{xs: 12, sm: 6, md: 6}}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={{xs: 12, sm: 12, md: 12}}>
                <TextField
                  fullWidth
                  label="Email address"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={{xs: 12, sm: 12, md: 12}}>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={{xs: 12, sm: 12, md: 12}}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "100%",
                    backgroundColor: "#ff3c4c",
                    color: "#fff",
                  }}
                >
                  Sign up
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Box>
    </Modal>
  );
}
