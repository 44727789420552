import React from "react";
import {Box, CardMedia, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {Link} from "react-router-dom";

import {CONSTANTS as c} from "constants/AppConstants";

import footerBgHome from "assets/images/footer-bg-home.png";
import footerPhone from "assets/images/footer-home-phone.png";
import appStore from "assets/images/appStore.PNG";
import googlePlay from "assets/images/googlePlay.PNG";

const AppPromotion = () => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100%",
      }}
    >
      {/* Background */}
      <Box
        sx={{
          position: "relative",
          height: {xs: "70vh", md: "100vh"},
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: {xs: "#E1251B", md: "none"},
          backgroundImage: {xs: "none", md: `url(${footerBgHome})`},
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          color: "white",
          textAlign: "center",
        }}
      >
        {/* Content */}
        <Grid container maxWidth={c.MAX_WIDTH}>
          {/* Phone Mockup */}
          <Grid size={{xs: 6}}>
            <Box
              component="img"
              src={footerPhone}
              alt="Phone"
              sx={{
                width: {xs: 150, md: 250},
                height: "auto",
              }}
            />
          </Grid>
          <Grid size={{xs: 6}}>
            {/* Text and Buttons */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "start",
                width: {xs: "100%", md: "80%"},
                height: "100%",
                textAlign: "left",
              }}
            >
              <Typography variant="h5" mb={2}>
                Download our App and...
              </Typography>
              <Typography variant="body1" mb={3}>
                <strong>Become a Foodie Member</strong> faster by uploading
                photos and rating the best restaurants in Interior from your
                phone.
              </Typography>
              <Box display="flex" gap={1}>
                {/* App Store Button */}
                <Link>
                  <CardMedia
                    component="img"
                    image={appStore}
                    alt="App Store"
                    sx={{width: {xs: 70, sm: 100, md: 150}}}
                  />
                </Link>
                <Link>
                  <CardMedia
                    component="img"
                    image={googlePlay}
                    alt="Google Play"
                    sx={{width: {xs: 70, sm: 100, md: 150}}}
                  />
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AppPromotion;
