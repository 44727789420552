import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Your email address is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Your password should be more than 6 characters."),
});
