import {Box, Typography, Avatar, Rating, Divider} from "@mui/material";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import {COLORS as color} from "constants/Colors";
import {Link} from "react-router-dom";

const UserDetails = ({comment}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flex: 1,
        mb: 1,
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to="/interior/profile/2">
          <Avatar
            sx={{
              width: 65,
              height: 65,
              bgcolor: color.lightGray1,
              color: color.neutralGray,
            }}
          />
        </Link>
        <Rating
          value={comment.userRating}
          defaultValue={2}
          sx={{
            "& .MuiRating-iconFilled": {
              color: color.text.info,
            },
            mt: 0.8,
          }}
          readOnly
          name="size-small"
          size="small"
        />
      </Box>
      <Box sx={{ml: 1}}>
        <Link to="/interior/profile/2" style={{textDecoration: "none"}}>
          <Typography sx={{color: color.text.textPrimary}}>
            <strong>{comment.name}</strong>
          </Typography>
        </Link>
        <Typography
          variant="body2"
          sx={{
            color: color.lightGray3,
            display: "inline-flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: color.text.primary,
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <EmojiEventsOutlinedIcon sx={{fontSize: "1rem"}} />
            {comment.points}
          </Typography>{" "}
          Points <Divider orientation="vertical" sx={{height: 20}} />
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: color.text.primary,
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <QuestionAnswerOutlinedIcon sx={{fontSize: "1rem"}} />{" "}
            {comment.commentsCount}{" "}
          </Typography>{" "}
          Comments
        </Typography>
        <Typography variant="body2" sx={{color: color.lightGray3}}>
          {comment.role}
        </Typography>
      </Box>
      <Divider
        orientation="vertical"
        sx={{
          height: 100,
          ml: "auto",
          display: {xs: "none", sm: "block"},
        }}
      />
    </Box>
  );
};

export default UserDetails;
