import React from "react";
import {
  Box,
  Typography,
  Button,
  CardMedia,
  CardContent,
  Badge,
} from "@mui/material";
import CardLayout from "components/Cards/CardLayout";
import {COLORS as color} from "constants/Colors";
import {Link} from "react-router-dom";

const RecommendationCard = ({
  image,
  heading,
  title,
  subtitle,
  rating,
  isButton,
}) => {
  return (
    <Link to="/" style={{textDecoration: "none"}}>
      <CardLayout
        title={heading}
        children={
          <>
            <CardContent
              sx={{
                p: 0,
                "&.MuiCardContent-root:last-child": {
                  pb: 0,
                  width: "100%",
                },
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{color: color.black}}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                sx={{color: color.text.info}}
                gutterBottom
              >
                {subtitle}
              </Typography>
              <Box position="relative" width="100%">
                <CardMedia
                  component="img"
                  image={image}
                  alt="Italian dish"
                  sx={{height: 120}}
                />

                <Badge
                  badgeContent={rating}
                  color="error"
                  sx={{
                    position: "absolute",
                    top: 14,
                    right: 18,
                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: "rgba(255, 0, 0, 0.8)",
                    "& .MuiBadge-badge": {
                      borderRadius: 0,
                      py: 1.8,
                      px: 1.3,
                    },
                  }}
                />
              </Box>
              {isButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{mt: 2, textTransform: "none"}}
                >
                  Free reservation
                </Button>
              )}
            </CardContent>
          </>
        }
      />
    </Link>
  );
};

export default RecommendationCard;
