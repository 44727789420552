import React from "react";
import {Box, Typography, Button} from "@mui/material";
import {COLORS as color} from "constants/Colors";

const BusinessClaimCard = () => {
  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        borderRadius: 2,
        padding: 2,
        textAlign: "center",
        mt: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        sx={{color: color.text.textPrimary}}
      >
        Is this your business?
      </Typography>
      <Typography variant="body2" sx={{mt: 1, color: color.lightGray3}}>
        Make sure your information is up to date. Also use our free tools to
        find new clients.
      </Typography>
      <Button
        variant="outlined"
        color="error"
        sx={{
          mt: 2,
          textTransform: "none",
          ":hover": {
            backgroundColor: color.bg.primary,
            color: color.text.secondary,
          },
        }}
      >
        Claim it now
      </Button>
    </Box>
  );
};

export default BusinessClaimCard;
