import React, {useRef} from "react";
import {Link} from "react-router-dom";
import {Box, Button, Divider, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {Swiper, SwiperSlide} from "swiper/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// Import Swiper styles
import "swiper/css";
import FeaturedCard from "components/Cards/FeaturedCard";
import {Opacity} from "@mui/icons-material";

const buttonStyle = {
  borderRadius: 0.2,
  color: "white",
  fontWeight: "bold",
  backgroundColor: "rgba(255, 0, 0, 0.8)",
  "& .MuiButton-startIcon": {
    mr: 0,
    ml: 1,
  },
  "&.MuiButton-root": {
    minWidth: "40px",
  },
  ":hover": {
    opacity: 0.9,
  },
};

const FeaturedRestaurant = ({data}) => {
  const swiperRef = useRef(null);

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Box width="100%">
      <Box sx={{width: "90%", mx: "auto"}}>
        <Link to="/" style={{textDecoration: "none"}}>
          <Typography
            variant="h5"
            textTransform="capitalize"
            fontWeight={600}
            sx={{
              color: "#2a2a2a",
              ":hover": {
                color: "#e8272a",
              },
            }}
          >
            Featured Restaurants
          </Typography>
        </Link>
        <Divider sx={{mt: 1, mb: 4}} />
      </Box>
      <Grid container spacing={1}>
        <Swiper
          ref={swiperRef}
          spaceBetween={20}
          slidesPerView={4}
          slidesPerGroup={1}
          centeredSlides={true}
          loop={true}
          slideToClickedSlide={true}
          className="mySwiper"
          breakpoints={{
            // Define breakpoints
            0: {
              slidesPerView: 1, // Show 1 slides on small screens (xs)
              spaceBetween: 10, // Adjust space between slides for small screens
            },
            375: {
              slidesPerView: 2, // Show 2 slides on small screens (xs)
              spaceBetween: 10, // Adjust space between slides for small screens
            },
            600: {
              slidesPerView: 4, // Show 4 slides on medium-small screens
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 4, // Show 4 slides on large screens
              spaceBetween: 20,
            },
          }}
        >
          {data.map((item, index) => (
            <Grid size={{xs: 12, lg: 12}} key={`feature-restaurant-${index}`}>
              <SwiperSlide>
                <FeaturedCard item={item} />
              </SwiperSlide>
            </Grid>
          ))}
        </Swiper>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          gap="0.2rem"
          marginTop="0.5rem"
        >
          <Button
            startIcon={<ArrowBackIosIcon />}
            onClick={handlePrevSlide}
            color="error"
            sx={buttonStyle}
          />
          <Button
            startIcon={<ArrowForwardIosIcon />}
            color="error"
            onClick={handleNextSlide}
            sx={buttonStyle}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default FeaturedRestaurant;
