import React from "react";
import {Tabs, Tab, Card, Divider, Typography, Box} from "@mui/material";
import {COLORS as color} from "constants/Colors";

const TabsComponent = ({
  children,
  noOfComments,
  tabItems,
  selectedTab,
  handleTabChange,
}) => {
  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: 1,
        boxShadow: 0,
        border: `1px solid ${color.border.info}`,
      }}
    >
      <Box sx={{display: "flex", alignItems: "center"}}>
        {noOfComments && (
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: color.text.primary,
              width: {xs: 280, sm: 150},
            }}
          >{` ${noOfComments} Comments`}</Typography>
        )}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            "& .MuiTab-root": {
              color: color.lightGray3,
              textTransform: "capitalize",
              fontSize: "1.1rem",
            },
            "& .Mui-selected": {
              color: color.text.error,
            },
            "& .MuiTabs-indicator": {
              backgroundColor: color.button.error,
            },
          }}
        >
          {tabItems && tabItems?.map((label) => <Tab label={label} />)}
        </Tabs>
      </Box>
      <Divider />
      {children}
    </Card>
  );
};

export default TabsComponent;
