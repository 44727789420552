import React, {useState} from "react";
import {Box, Typography, Divider} from "@mui/material";
import Grid from "@mui/material/Grid2";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import {COLORS as color} from "constants/Colors";
import UserDetails from "./UserDetails";
import FoodsRating from "./FoodsRating";

const commentsData = [
  {
    name: "Daniel G.",
    points: 76,
    commentsCount: 1,
    role: "Connoisseur",
    userRating: 4,
    mealRating: 2,
    serviceRating: 3,
    atmosphereRating: 4,
    comment:
      "Excellent everything. The pizzas are very tasty as always. Good atmosphere and excellent service. Estephany in particular gave us excellent service.",
    date: "11/16/2024",
  },
];

const likeStyle = {color: color.lightGray3, cursor: "pointer"};

const Comments = ({selectedTab}) => {
  const [like, setLike] = useState(false);

  const handleCommentLike = () => {
    setLike(!like);
  };

  return (
    <Box>
      {/* Comments */}
      {selectedTab === 0 && (
        <Box sx={{mt: 2}}>
          <Grid container spacing={1}>
            {commentsData?.map((comment, index) => (
              <React.Fragment key={index}>
                <Grid size={{xs: 12, sm: 6}}>
                  <UserDetails comment={comment} />
                </Grid>
                <Grid size={{xs: 12, sm: 4}}>
                  <Divider
                    sx={{mb: 1.5, mx: 2, display: {xs: "block", sm: "none"}}}
                  />
                  <FoodsRating comment={comment} />
                </Grid>
                <Grid size={{xs: 12, sm: 2}}>
                  <Typography
                    variant="body2"
                    sx={{
                      display: {xs: "none", sm: "block"},
                      textAlign: "right",
                      px: 1,
                      color: color.lightGray3,
                    }}
                  >
                    {comment.date}
                  </Typography>
                </Grid>
                <Grid size={{xs: 12, lg: 12}}>
                  <Box sx={{px: {xs: 1, sm: 2}}}>
                    <Typography
                      variant="body2"
                      sx={{mt: 1, color: color.text.primary}}
                    >
                      {comment.comment}
                    </Typography>
                  </Box>
                  <Box sx={{display: {xs: "block", sm: "none"}}}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: color.lightGray3,
                        textAlign: "right",
                        px: 1,
                        mt: 1,
                      }}
                    >
                      {comment.date}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  size={{xs: 12, lg: 12}}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderBottom={2}
                  borderTop={1}
                  borderColor={color.border.info}
                  padding={1}
                  marginTop={2}
                >
                  <Box
                    sx={{
                      flex: {xs: 1, sm: 1},
                      textAlign: "center",
                    }}
                  >
                    {like ? (
                      <FavoriteIcon
                        sx={likeStyle}
                        onClick={handleCommentLike}
                      />
                    ) : (
                      <FavoriteBorderIcon
                        sx={likeStyle}
                        onClick={handleCommentLike}
                      />
                    )}
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{color: color.lightGray3}}
                    >
                      {like ? 1 : 0} like
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" sx={{mr: 4}} />
                  <Box
                    sx={{
                      flex: {xs: 1, sm: 5},
                      display: "flex",
                      alignItems: "center",
                      gap: 0.4,
                      cursor: "pointer",
                    }}
                  >
                    <ScreenShareOutlinedIcon sx={{color: color.lightGray3}} />
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{color: color.lightGray3}}
                    >
                      Share
                    </Typography>
                  </Box>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Comments;
