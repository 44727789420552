import React from "react";
import {useField} from "formik";
import {InputAdornment, TextField} from "@mui/material";

const InputField = ({name, label, placeholder, icon, ...props}) => {
  const [field, meta] = useField(name);
  const isError = meta.touched && meta?.error && Boolean(meta?.error);

  return (
    <TextField
      {...field}
      {...props}
      label={label}
      error={isError}
      placeholder={placeholder}
      helperText={meta?.touched && meta?.error ? meta.error : ""}
      variant="outlined"
      slotProps={
        icon && {
          input: {
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
          },
        }
      }
    />
  );
};

export default InputField;
