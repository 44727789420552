import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Button, Slide} from "@mui/material";

import SignupModal from "../Modals/SignUp";
import LoginModal from "../Modals/Login";
import {COLORS as color} from "constants/Colors";

const navStyles = {
  cursor: "pointer",
  textTransform: "capitalize",
  margin: "0 0.5rem",
  color: color.text.secondary,
  textAlign: "center",
  textDecoration: "none",
};

const navLinkStyles = {
  opacity: 0.9,
  fontWeight: 400,
  textDecoration: "none",
};

const active = {
  textDecoration: "none",
  fontWeight: 800,
  opacity: 1,
};

const Navbar = () => {
  const theme = useTheme();
  const currentPage = useLocation().pathname;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const handleSignUpOpen = () => setOpenSignUp(true);
  const handleSignUpClose = () => setOpenSignUp(false);

  const handleLoginOpen = () => setOpenLogin(true);
  const handleLoginClose = () => setOpenLogin(false);

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleDrawerClose = () => {
    console.log("close runnoing");
    setDrawerOpen(false);
  };

  const navLinks = [
    {text: "Interior", path: "/"},
    {text: "User ranking", path: "/user-ranking"},
    {text: "Add Restaurant", path: "/add-restaurant"},
    {text: "Contact us", path: "/contact"},
    {text: "Point system", path: "/point-system"},
    {text: "Terms and conditions", path: "/terms"},
  ];

  const list = () => (
    <Box
      role="presentation"
      sx={{
        backgroundColor: color.bg.primary,
        width: "100%",
      }}
      onClick={handleDrawerClose}
    >
      <Slide direction="down" in={drawerOpen} mountOnEnter unmountOnExit>
        <List>
          <ListItem button component={Link} to="" onClick={handleLoginOpen}>
            <ListItemText primary="Sign in" sx={navStyles} />
          </ListItem>
          <ListItem button component={Link} to="" onClick={handleSignUpOpen}>
            <ListItemText primary="Create an account" sx={navStyles} />
          </ListItem>
          {navLinks.map(({text, path}) => (
            <ListItem
              key={text}
              button
              component={Link}
              to={path}
              onClick={() => {
                if (text === "Sign in") {
                  handleLoginOpen();
                } else {
                  handleDrawerClose();
                }
              }}
              style={currentPage === path ? active : navLinkStyles}
            >
              <ListItemText primary={text} sx={navStyles} />
            </ListItem>
          ))}
        </List>
      </Slide>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        width: "100%",
        backgroundColor: color.bg.primary,
        mx: "auto",
        display: "flex",
        alignItems: "center",
        py: 1.5,
        boxShadow: 0,
      }}
    >
      {/* OPEN SIGNUP MODAL */}
      {openSignUp && (
        <SignupModal open={openSignUp} handleClose={handleSignUpClose} />
      )}
      {/* OPEN LOGIN MODAL */}
      {openLogin && (
        <LoginModal open={openLogin} handleClose={handleLoginClose} />
      )}
      <Toolbar
        sx={{
          maxWidth: "1400px",
          width: "90%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          style={{flexGrow: 1, zIndex: "2222", fontSize: "2rem"}}
        >
          <Link to="/" style={navStyles}>
            degusta
          </Link>
        </Typography>
        {isMobile ? (
          <Box>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{zIndex: "2222"}}
            >
              {drawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Box>
        ) : (
          <Box>
            {/* <Button
            variant="contained"
            color="default"
            style={{
              backgroundColor: "#b30000",
              color: "#fff",
              marginRight: "10px",
            }}
          >
            Inside
          </Button> */}
            <Button
              style={{
                marginRight: "10px",
                fontWeight: 600,
                color: color.text.secondary,
              }}
              onClick={handleLoginOpen}
            >
              Sign in
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleSignUpOpen}
              sx={{
                border: `2px solid ${color.white}`,
                fontWeight: 600,
                color: color.text.secondary,
              }}
            >
              Create an account
            </Button>
          </Box>
        )}
        {drawerOpen && (
          <Drawer
            anchor="top"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": {
                position: "relative",
                top: "14%",
                boxShadow: 0,
              },
              "& .MuiModal-backdrop": {
                top: "unset",
              },
            }}
          >
            {list()}
          </Drawer>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
