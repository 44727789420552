import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Layout from "../layout";
import Home from "pages/Home";
import InteriorDetail from "pages/Interior/RestaurantDetails";
import CollectionDetails from "pages/Interior/Recommendations";
import SearchFilters from "pages/Interior/SearchFilters";
import AddRestaurant from "pages/Interior/AddRestaurant";
import Profile from "pages/Interior/RestaurantDetails/Profile";
import Error404 from "components/Error404";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/interior/profile/:id",
        element: <Profile />,
      },
      {
        path: "/interior/add-restaurant",
        element: <AddRestaurant />,
      },
      {
        path: "/interior/restaurant/:id",
        element: <InteriorDetail />,
      },
      {
        path: "/interior/recommendations/:id",
        element: <CollectionDetails />,
      },
      {
        path: "/interior/:id",
        element: <SearchFilters />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
