import React from "react";
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid2";

import {CONSTANTS as c} from "constants/AppConstants";
import {COLORS as color} from "constants/Colors";
import AdvancedFilters from "components/Common/AdvancedFilters";
import ReviewsTab from "./components/ReviewsTab";
import UserProfileCard from "./components/userProfileCard";

const contentStyles = {
  maxWidth: c.MAX_WIDTH,
  mx: "auto",
  width: "80%",
  mb: 6,
};
const UserProfile = () => {
  const id = "eyJmaWx0ZXJzIjp7ImRpc2NvdW50cyI6dHJ1ZX0sInNvcnQiOiJkaXNjb3VudCJ9";

  return (
    <Box>
      <AdvancedFilters id={id} />/
      <Box sx={contentStyles}>
        <Grid container spacing={2}>
          {/* Left Profile Section */}
          <Grid size={{xs: 12, sm: 3}}>
            <UserProfileCard />
          </Grid>

          {/* Right Reviews Section */}
          <Grid size={{xs: 12, sm: 9}}>
            <ReviewsTab />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UserProfile;
