import React from "react";
import {Link} from "react-router-dom";
import {Box} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import PercentIcon from "@mui/icons-material/Percent";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";

import {containerStyle, tabMainStyle, tabItemStyle} from "./styles";

const IconTabs = () => {
  const tabValues = [
    {
      label: "Ranking",
      icon: <BarChartIcon color="success" />,
      path: "interior/restaurant",
    },
    {
      label: "Discounts",
      icon: <PercentIcon color="success" />,
      path: "interior/restaurant",
    },
    {
      label: "Area",
      icon: <LocationOnIcon color="error" />,
      path: "interior/restaurant",
    },
    {
      label: "Kitchen",
      icon: <RestaurantIcon color="success" />,
      path: "interior/restaurant",
    },
    {
      label: "Breakfast",
      icon: <FreeBreakfastIcon color="primary" />,
      path: "interior/restaurant",
    },
    {
      label: "Lunch",
      icon: <LunchDiningIcon color="secondary" />,
      path: "interior/restaurant",
    },
    {
      label: "Dinner",
      icon: <DinnerDiningIcon color="success" />,
      path: "interior/restaurant",
    },
    {
      label: "See all",
      icon: <ArrowForwardIosIcon color="success" />,
      path: "interior/restaurant",
    },
  ];
  return (
    <Box sx={containerStyle}>
      <Box sx={tabMainStyle}>
        {tabValues.map(({label, icon, path}, index) => (
          <Link to={`${path}/${index}`} style={{textDecoration: "none"}}>
            <Box key={label} sx={tabItemStyle}>
              {icon}
              {label}
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default IconTabs;
