import React, {useState} from "react";
import {Typography, Button, Box} from "@mui/material";
import {COLORS as color} from "constants/Colors";

const textStyle = {
  color: color.lightGray3,
  display: "flex",
  alignItems: "center",
  my: 0.3,
};

const Schedule = () => {
  const [showFullSchedule, setShowFullSchedule] = useState(false);

  const handleToggle = () => {
    setShowFullSchedule(!showFullSchedule);
  };

  return (
    <Box>
      {/* Always visible */}
      <Typography
        variant="body2"
        fontWeight={showFullSchedule ? 600 : 400}
        sx={textStyle}
      >
        Today 11:30am to 9:30pm{" "}
        <Button
          onClick={handleToggle}
          size="small"
          sx={{
            fontSize: "inherit",
            textTransform: "none",
            padding: 0,
            minWidth: "auto",
            color: color.lightGray3,
            ml: 1,
          }}
        >
          <span>{showFullSchedule ? "▼" : "▶"}</span>
        </Button>
      </Typography>

      {/* Conditionally visible */}
      {showFullSchedule && (
        <Box>
          <Typography variant="body2" sx={textStyle}>
            Tuesday 11:30am to 9:30pm
          </Typography>
          <Typography variant="body2" sx={textStyle}>
            Wednesday 11:30am to 9:30pm
          </Typography>
          <Typography variant="body2" sx={textStyle}>
            Thursday 11:30am to 9:30pm
          </Typography>
          <Typography variant="body2" sx={textStyle}>
            Friday 11:30am to 10:00pm
          </Typography>
          <Typography variant="body2" sx={textStyle}>
            Saturday 11:30am to 10:00pm
          </Typography>
          <Typography variant="body2" sx={textStyle}>
            Sunday 11:30am to 9:30pm
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Schedule;
