import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import {COLORS as color} from "constants/Colors";
import {Link} from "react-router-dom";

const FeaturedCard = ({item: {title, id, menu, cover, location, rank}}) => {
  console.log(color, "color");
  return (
    <Link
      to={`/interior/recommendations/${id}`}
      style={{textDecoration: "none"}}
    >
      <Card
        sx={{
          maxWidth: {xs: 300, md: 300},
          position: "relative",
          boxShadow: 0,
          borderRadius: 0,
          borderBottom: 1,
          borderColor: color.border.info,
          my: 1,
          mx: "auto",
          ":hover": {
            borderColor: color.firebrickRed,
            transition: "100ms ease-in",
          },
        }}
      >
        <Box sx={{position: "relative"}}>
          <CardMedia component="img" height="240" image={cover} alt={title} />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: color.overlay.secondary,
              zIndex: 0,
            }}
          />
          <Chip
            label={rank}
            color="error"
            sx={{
              borderRadius: 0.2,
              position: "absolute",
              top: 8,
              right: 8,
              color: color.text.secondary,
              fontWeight: "bold",
              backgroundColor: color.crimsonRed,
            }}
          />

          <Typography
            variant="body1"
            textTransform="capitalize"
            sx={{position: "absolute", bottom: 15, left: 80, color: "#fff"}}
          >
            5 places
          </Typography>
        </Box>

        {/* Card Content */}
        <CardContent sx={{mt: 2}}>
          {/* Location */}
          <Typography
            variant="body1"
            textTransform="capitalize"
            textAlign="center"
            fontWeight={600}
          >
            pleasure of the sea
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default FeaturedCard;
