import React from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import coffee2 from "assets/images/coffee2.jpg";
import pizza from "assets/images/pizza.jpg";
import homeBg from "assets/images/homeBg.jpg";

const photos = [
  {
    id: 1,
    title: "Hamburger joint",
    location: "San Francisco",
    image: coffee2,
    caption: "",
    date: "November 18th",
  },
  {
    id: 2,
    title: "Hamburger joint",
    location: "San Francisco",
    image: pizza,
    caption: "To Start - Chicken Wings",
    date: "November 18th",
  },
  {
    id: 3,
    title: "Hamburger joint",
    location: "San Francisco",
    image: homeBg,
    caption: "To Start - Chicken Wings",
    date: "November 18th",
  },
];

const PhotoGallery = () => {
  return (
    <Box sx={{padding: "16px"}}>
      <Typography
        variant="subtitle1"
        sx={{marginBottom: "16px", fontWeight: "bold"}}
      >
        515 photos in <span style={{color: "#D32F2F"}}>Panama</span>
      </Typography>
      <Grid container spacing={2}>
        {photos.map((photo) => (
          <Grid size={{xs: 12, sm: 6, md: 4}} key={photo.id}>
            <Card sx={{borderRadius: "8px", boxShadow: 1}}>
              <CardMedia
                component="img"
                height="200"
                image={photo.image}
                alt={photo.caption || photo.title}
              />
              <CardContent>
                <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
                  {photo.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {photo.location}
                </Typography>
                {photo.caption && (
                  <Typography
                    variant="body2"
                    sx={{marginTop: "8px", fontStyle: "italic"}}
                  >
                    {photo.caption}
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  sx={{display: "block", marginTop: "8px"}}
                >
                  📅 {photo.date}
                </Typography>
                <Box
                  sx={{marginTop: "8px", display: "flex", alignItems: "center"}}
                >
                  <IconButton size="small">
                    <FavoriteBorderIcon fontSize="small" />
                  </IconButton>
                  <Typography variant="body2">I like</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PhotoGallery;
