import React, {useState} from "react";
import {Box, Typography, TextField, Button} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DishesRating from "./DishesRating";
import {COLORS as color} from "constants/Colors";

const RecommendedDishes = () => {
  const [dishes, setDishes] = useState([
    {name: "Whole fish", votes: 2, isVoted: false},
    {name: "Bisque", votes: 2, isVoted: false},
    // {name: "Guacho", votes: 3, isVoted: false},
  ]);
  const [newDish, setNewDish] = useState("");

  const handleVote = (index) => {
    const updatedDishes = dishes.map((dish, i) =>
      i === index
        ? {
            ...dish,
            votes: dish.isVoted ? dish.votes - 1 : dish.votes + 1,
            isVoted: !dish.isVoted,
          }
        : dish
    );
    setDishes(updatedDishes);
  };

  const handleAddDish = () => {
    if (newDish.trim() !== "") {
      setDishes([...dishes, {name: newDish, votes: 0, isVoted: false}]);
      setNewDish("");
    }
  };

  const maxVotes = Math.max(...dishes.map((dish) => dish.votes)) || 1;

  return (
    <Box sx={{p: 2, border: "1px solid #ddd", borderRadius: 2}}>
      <Typography variant="h6" gutterBottom>
        Recommended Dishes
      </Typography>
      <Grid container spacing={2}>
        <Grid
          size={{xs: 12, sm: 6}}
          sx={{
            borderRight: {xs: 0, sm: 1},
            borderColor: {xs: "none", sm: color.border.info},
          }}
        >
          {dishes.map((dish, index) => (
            <Grid key={index} size={{xs: 12}}>
              <DishesRating
                index={index}
                dish={dish}
                handleVote={handleVote}
                maxVotes={maxVotes}
              />
            </Grid>
          ))}
        </Grid>
        <Grid size={{xs: 12, sm: 6}}>
          <DishesRating
            index={2}
            dish={{name: "Guacho", votes: 3, isVoted: false}}
            handleVote={handleVote}
            maxVotes={maxVotes}
          />
        </Grid>
      </Grid>
      <Box sx={{display: "flex", alignItems: "center", gap: 1, mt: 2}}>
        <TextField
          value={newDish}
          onChange={(e) => setNewDish(e.target.value)}
          label="Recommend a  dish of food"
          variant="outlined"
          fullWidth
          size="medium"
          sx={{flex: 3}}
        />
        <Button
          variant="contained"
          fullWidth
          size="large"
          sx={{flex: 1, py: 1.7, backgroundColor: color.blue}}
          onClick={handleAddDish}
        >
          Recommend
        </Button>
      </Box>
    </Box>
  );
};

export default RecommendedDishes;
