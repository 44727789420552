import React from "react";
import Grid from "@mui/material/Grid2";
import {Box, Typography, CardMedia} from "@mui/material";

import {CONSTANTS as c} from "constants/AppConstants";
import {COLORS as color} from "constants/Colors";

import FoodCard from "components/Cards/FoodCard";
import AdvancedFilters from "components/Common/AdvancedFilters";
import ScrollToTop from "components/Common/ScrollToTop";

import pizzaImg from "assets/images/pizza.jpg";

const contentStyles = {
  maxWidth: c.MAX_WIDTH,
  mx: "auto",
  width: "85%",
  my: 4,
};
const CollectionDetails = () => {
  const id = "eyJmaWx0ZXJzIjp7ImRpc2NvdW50cyI6dHJ1ZX0sInNvcnQiOiJkaXNjb3VudCJ9";

  return (
    <Box>
      {/* Scrolled the page when the component mounted. */}
      <ScrollToTop />
      {/* Start ,advanced search  and discount buttons */}
      <AdvancedFilters id={id} />
      <Box sx={contentStyles}>
        <Grid container spacing={1}>
          {/* Image section */}
          <Grid size={{xs: 12}}>
            <Box
              sx={{
                position: "relative",
                maxWidth: "100%",
                height: "350px",
                overflow: "hidden",
              }}
            >
              {/* Background image */}
              <CardMedia
                component="img"
                image={pizzaImg}
                alt="Carnivores"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>

          {/* Description section */}
          <Grid size={{xs: 12}}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              width="100%"
              paddingY={2}
            >
              <Typography
                variant="h3"
                sx={{fontSize: "2.57rem", color: color.black}}
              >
                With the flavor of Panama
              </Typography>
              <Typography
                variant="h5"
                sx={{color: color.text.info, fontSize: "1.28rem"}}
              >
                Try the best typical dishes
              </Typography>
            </Box>
          </Grid>
          {Array.from({length: 10}).map((_, index) => (
            <Grid size={{xs: 12, sm: 6, md: 4, lg: 3}}>
              <FoodCard key={index} image={pizzaImg} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CollectionDetails;
