import React from "react";
import {Link} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {COLORS as color} from "constants/Colors";
import percentImg from "assets/images/percentBg.png";

const linkStyles = {
  textDecoration: "none",
};

const AdvancedFilters = ({id}) => {
  const queryParams = new URLSearchParams({filters: id}).toString();

  return (
    <Box
      sx={{
        display: "flex",
        gap: 4,
        alignItems: "center",
        justifyContent: "center",
        borderBottom: 1,
        borderColor: color.border.info,
        py: 1,
      }}
    >
      <Link to={`/`} style={linkStyles}>
        <Typography
          variant="subtitle1"
          textTransform="capitalize"
          fontWeight={700}
          sx={{
            color: color.black,
            fontSize: "1.05rem",
            ":hover": {
              color: color.text.error,
            },
          }}
        >
          Start
        </Typography>
      </Link>
      <Link to={`/interior/search`} style={linkStyles}>
        <Typography
          variant="subtitle1"
          textTransform="capitalize"
          fontWeight={700}
          sx={{
            color: color.black,
            fontSize: "1.05rem",
            ":hover": {
              color: color.text.error,
            },
          }}
        >
          Advanced Search
        </Typography>
      </Link>
      <Link to={`/interior/search?${queryParams}`} style={linkStyles}>
        <Typography
          variant="subtitle1"
          textTransform="capitalize"
          fontWeight={700}
          sx={{
            color: color.black,
            fontSize: "1.05rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 0.5,

            ":hover": {
              color: color.text.error,
            },
          }}
        >
          <Typography
            component="span"
            sx={{
              display: "inline-flex",
              backgroundImage: `url(${percentImg})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: 26,
              height: 26,
              color: color.text.secondary,
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 800,
            }}
          >
            %
          </Typography>{" "}
          Discounts
        </Typography>
      </Link>
    </Box>
  );
};

export default AdvancedFilters;
