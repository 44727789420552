import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {COLORS as color} from "constants/Colors";
import {Link} from "react-router-dom";

const ratingStyles = {color: color.text.textPrimary, fontWeight: 600};
const cardStyles = {
  fontSize: "0.9rem",
  color: color.text.info,
  textTransform: "capitalize",
};

const foodRating = [
  {label: "meal", value: 4.5},
  {label: "Service", value: 3.4},
  {label: "Atmosphere", value: 3.7},
];

const FoodCard = ({image}) => {
  return (
    <Link to={`/interior/restaurant/4`} style={{textDecoration: "none"}}>
      <Card
        sx={{
          maxWidth: 345,
          boxShadow: 0,
          borderRadius: 0,
          border: 1,
          borderColor: color.border.info,
          ":hover": {
            boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 20px",
            transition: "100ms ease-in",
          },
        }}
      >
        {/* Image */}
        <CardMedia component="img" height="180" image={image} alt="Food item" />

        {/* Content */}
        <CardContent
          sx={{
            p: 1,
            "&.MuiCardContent-root:last-child": {
              pb: 1,
            },
          }}
        >
          {/* Title and Price */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid size="grow">
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.22rem",
                  color: color.text.textPrimary,
                }}
              >
                The Carbonera
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  color: color.dangerRed,
                }}
              >
                $20
              </Typography>
            </Grid>
          </Grid>

          {/* Subtitle */}
          <Typography variant="body2" sx={cardStyles}>
            volcano
          </Typography>
          <Typography variant="body2" sx={cardStyles}>
            pizzeria, grill
          </Typography>
          {/* Ratings Section */}
          <Divider sx={{my: 1}} />
          <Grid container spacing={2}>
            {/* Meal */}
            <Grid size={{xs: 12}}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                gap={1}
              >
                {foodRating.map((item) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: color.text.info,
                        textAlign: "center",
                        lineHeight: 0,
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        sx={ratingStyles}
                      >
                        {item?.value}
                      </Typography>
                      /5
                    </Typography>
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{
                        color: color.lightGray3,
                        fontWeight: 600,
                        lineHeight: 0.9,
                        textTransform: "capitalize",
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                ))}

                {/* Votes */}
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      color: color.lightGray3,
                      fontWeight: 600,
                    }}
                  >
                    226
                  </Typography>{" "}
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{color: color.lightGray3, lineHeight: 0.9}}
                  >
                    Votes
                  </Typography>{" "}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
};

export default FoodCard;
