import {Box, Typography, Paper, Divider} from "@mui/material";
import {COLORS as color} from "constants/Colors";

const CardLayout = ({title, children}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderColor: color.border.info,
        width: "100%",
        borderRadius: 1,
      }}
    >
      {title && (
        <>
          <Box sx={{p: 1}}>
            <Typography variant="h6" fontWeight={500}>
              {title}
            </Typography>
          </Box>
          <Divider />
        </>
      )}
      <Box sx={{display: "flex", gap: "0.5rem", p: 1.5}}>{children}</Box>
    </Paper>
  );
};

export default CardLayout;
