import React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {COLORS as color} from "constants/Colors";

const RestaurantRating = ({label, value, total}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        textAlign: "center",
        borderColor: color.border.info,
        width: "100%",
        borderRadius: 1,
      }}
    >
      <Box sx={{backgroundColor: color.lightGray2, py: 0.5}}>
        <Typography sx={{fontSize: "1.28571rem", color: color.mediumGray}}>
          {label}
        </Typography>
      </Box>
      <Box sx={{p: 1}}>
        <Typography variant="h6" component="span" fontWeight="bold">
          {value}
        </Typography>
        {total && (
          <Typography
            component="span"
            fontWeight="bold"
            sx={{color: color.text.info}}
          >
            /{total}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default RestaurantRating;
