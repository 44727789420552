// Red Variants
const brightRed = "#ff0000";
const crimsonRed = "rgba(255, 0, 0, 0.8)";
const firebrickRed = "#B91F17";
const cherryRed = "#d10510";
const scarletRed = "#e8272a";
const dangerRed = "#E1251B";
const brickRed = "#A41B14";
const orange = "#fe541e";
// Neutral Colors
const white = "#fff";
const offWhite = "#eee";
const black = "#000";
const lightGray0 = "#616161";
const lightGray1 = "#e0e0e0";
const lightGray2 = "#eaeaea";
const lightGray3 = "#8e8e8e";
const lightGray4 = "#cccccc";
const translucentDarkBlack = "rgba(0, 0, 0, 0.7)";
const translucentBlack = "rgba(0, 0, 0, 0.3)";
const lightBlack = "#2a2a2a";
const translucentBlackLight = "#00000020";
const darkGray = "#6d6d6d";
const softWhite = "#f5f5f5";
const neutralGray = "#b0b0b0";
const mutedGray = "#777";
// overlay
const dimmingOverlay =
  "linear-gradient(to bottom, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.7) 90%)";

const lightBlue = "#6DA3EE";
const mediumGray = "#555";
const blue = "#165BBC";
const darkBlue = "#1A6FE5";
const greenCane = "#11e280";

export const COLORS = {
  bg: {
    info: brightRed,
    primary: dangerRed,
    secondary: white,
  },

  text: {
    info: darkGray,
    primary: lightGray0,
    secondary: white,
    textPrimary: lightBlack,
    error: scarletRed,
  },

  button: {
    info: brickRed,
    primary: firebrickRed,
    secondary: neutralGray,
    error: dangerRed,
  },

  badge: {
    info: neutralGray,
    primary: dangerRed,
    secondary: softWhite,
  },

  border: {
    info: lightGray1,
    primary: neutralGray,
    secondary: softWhite,
    error: scarletRed,
  },

  overlay: {
    info: translucentDarkBlack,
    primary: translucentBlack,
    secondary: dimmingOverlay,
  },

  brightRed,
  crimsonRed,
  firebrickRed,
  cherryRed,
  scarletRed,
  dangerRed,
  brickRed,
  white,
  black,
  lightGray0,
  lightGray1,
  lightGray2,
  lightGray3,
  translucentBlack,
  translucentDarkBlack,
  translucentBlackLight,
  darkGray,
  softWhite,
  neutralGray,
  lightBlue,
  lightBlack,
  greenCane,
  blue,
  mediumGray,
  darkBlue,
  mutedGray,
  orange,
  offWhite,
  lightGray4,
};
