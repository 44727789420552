import React, {useState} from "react";
import Grid from "@mui/material/Grid2";
import {Box, Typography, Card, Divider} from "@mui/material";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";

import Tabs from "components/Tabs";

import {COLORS as color} from "constants/Colors";
import Reviews from "../Reviews";
import PhotoGallery from "../Photos";
import Visited from "../Visited";

const likeStyle = {color: color.lightGray3, cursor: "pointer"};

const ReviewsTab = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabItems = ["Reviews (64)", "Photos (159)", `Visited (48)`];

  const [like, setLike] = useState(false);

  const handleCommentLike = () => {
    setLike(!like);
  };

  return (
    <Box>
      {/* Tabs Section */}

      <Tabs
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        tabItems={tabItems}
      >
        {selectedTab === 0 && (
          <>
            <Grid container spacing={1}>
              {/* Ranking Info */}
              <Grid size={{xs: 12, sm: 12}}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                    borderColor: color.border.info,
                    mx: 3,
                    mt: 2,
                  }}
                >
                  <Card
                    sx={{
                      width: "100%",
                      p: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      boxShadow: 0,
                      border: 1,
                      borderColor: color.border.info,
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        backgroundColor: "#f39c12",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "2.3rem",
                        height: "2.3rem",
                      }}
                    >
                      <SignalCellularAltIcon />
                    </Typography>
                    <Typography sx={{marginRight: 1, fontWeight: 500}}>
                      Position <span style={{color: "#f39c12"}}>2546</span> in
                      the Panama qualifiers ranking.
                    </Typography>
                  </Card>
                </Box>
              </Grid>
              <Grid size={{xs: 12, sm: 12}}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    pb: 2,
                    mx: 3,
                    borderBottom: 1,
                    borderColor: color.border.info,
                  }}
                >
                  {" "}
                  <ChatBubbleIcon sx={{color: "#11e280"}} />{" "}
                  <Typography color="text.secondary">
                    2 comments in{" "}
                    <span style={{color: color.text.error, fontWeight: 500}}>
                      Panama
                    </span>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{xs: 12, sm: 12}}>
                {/* Review Cards */}
                <Reviews />
              </Grid>
              {/* Actions */}
              <Grid
                size={{xs: 12, lg: 12}}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderTop={1}
                borderColor={color.border.info}
                padding={1}
                marginTop={2}
              >
                <Box
                  sx={{
                    flex: {xs: 1, sm: 1},
                    textAlign: "center",
                  }}
                >
                  {like ? (
                    <FavoriteIcon sx={likeStyle} onClick={handleCommentLike} />
                  ) : (
                    <FavoriteBorderIcon
                      sx={likeStyle}
                      onClick={handleCommentLike}
                    />
                  )}
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{color: color.lightGray3}}
                  >
                    {like ? 1 : 0} like
                  </Typography>
                </Box>
                <Divider orientation="vertical" sx={{mr: 4}} />
                <Box
                  sx={{
                    flex: {xs: 1, sm: 5},
                    display: "flex",
                    alignItems: "center",
                    gap: 0.4,
                    cursor: "pointer",
                  }}
                >
                  <ScreenShareOutlinedIcon sx={{color: color.lightGray3}} />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{color: color.lightGray3}}
                  >
                    Share
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        )}

        {selectedTab === 1 && (
          <Box sx={{paddingTop: 2}}>
            <PhotoGallery />
          </Box>
        )}

        {selectedTab === 2 && (
          <Box sx={{paddingTop: 2}}>
            <Visited />{" "}
          </Box>
        )}
      </Tabs>
    </Box>
  );
};

export default ReviewsTab;
