import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Navbar from "components/NavBar";
import Footer from "components/Footer";
import {Outlet} from "react-router-dom";

const Layout = () => {
  return (
    <Box sx={{flexGrow: 1, overflow: "hidden"}}>
      <Grid container spacing={0}>
        <Grid size={12}>
          <Navbar />
        </Grid>
        <Grid size={12} sx={{minHeight: "100vh", height: "100%", mt: "85px"}}>
          <Outlet />
        </Grid>
        <Grid size={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Layout;
