import React from "react";
import {Typography, Divider, Select, MenuItem, Box, Chip} from "@mui/material";
import Grid from "@mui/material/Grid2";
import RestaurantCard from "./components/RestaurantCard";
import Filters from "./components/Filters";
import AdvancedFilters from "components/Common/AdvancedFilters";
import {COLORS as color} from "constants/Colors";

import pizzaImg from "assets/images/coffee1.jpg";
import Map from "components/Map";

const SearchFilters = () => {
  const id = "eyJmaWx0ZXJzIjp7ImRpc2NvdW50cyI6dHJ1ZX0sInNvcnQiOiJkaXNjb3VudCJ9";
  const kitchen = ["German", "American", "Arab", "Argentina"];
  const discount = ["Discount"];
  const schedule = ["Open Now"];
  const Prices = [
    "Up to $12",
    "From to $12 to $20",
    "From to $12 to $30",
    "More than $30",
  ];

  return (
    <Box>
      <AdvancedFilters id={id} />
      <Box sx={{width: "90%", mx: "auto", maxWidth: "1400px", mt: 1}}>
        <Grid container spacing={2}>
          {/* Left Filters Column */}
          <Grid size={{xs: 0, sm: 3}}>
            <Box sx={{display: {xs: "none", sm: "block"}}}>
              <Filters
                title="Promotions"
                options={discount}
                showPercentage={true}
              />
              <Filters
                title="Schedule"
                options={schedule}
                showPercentage={false}
              />
              <Filters title="Prices" options={Prices} showPercentage={false} />
              <Filters
                title="Kitchen"
                options={kitchen}
                showPercentage={false}
              />
            </Box>
          </Grid>

          {/* Center Results Column */}
          <Grid
            size={{xs: 12, sm: 9}}
            sx={{
              mb: 2,
              borderLeft: {xs: 0, md: `1px solid ${color.offWhite}`},
              borderRadius: 0,
            }}
          >
            {/* Results Header */}
            <Grid container spacing={1}>
              <Grid size={{xs: 12}}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    gap: 8,
                    py: 2,
                  }}
                >
                  <Chip label="745 results" sx={{borderRadius: 0}} />
                  <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                    <Typography>Sort by:</Typography>
                    <Select size="small" defaultValue="Service">
                      <MenuItem value="Service">Service</MenuItem>
                      <MenuItem value="Price">Price</MenuItem>
                      <MenuItem value="Rating">Rating</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Divider />
              </Grid>

              {/* Single Result Card */}
              <Grid size={{xs: 12, sm: 12, md: 9, lg: 8}}>
                <Grid container spacing={2} sx={{px: {xs: 0, sm: 2}}}>
                  {Array.from({length: 3}).map((_, index) => (
                    <Grid size={{xs: 12}}>
                      <RestaurantCard
                        key={`restaurant-${index}`}
                        image={pizzaImg}
                        id={index}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid size={{xs: 0, md: 3, lg: 4}}>
                {/* start */}

                {/* Right Map Column */}
                <Box
                  sx={{
                    width: "100%",
                    height: 280,
                    border: "1px solid #ddd",
                    borderRadius: 0,
                    display: {xs: "none", md: "flex"},
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <Map />
                </Box>
                {/* end */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SearchFilters;
