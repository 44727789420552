import React, {useState} from "react";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
  Box,
  Chip,
} from "@mui/material";
import {COLORS as color} from "constants/Colors";
import percentImg from "assets/images/percentBg.png";

const Filter = ({title, options, showPercentage = false}) => {
  // State to track selected checkboxes
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    setSelectedOptions(
      (prevSelected) =>
        prevSelected.includes(option)
          ? prevSelected.filter((item) => item !== option) // Deselect
          : [...prevSelected, option] // Select
    );
  };

  return (
    <Box sx={{my: 3}}>
      <Typography variant="h6" mb={1} sx={{color: color.mediumGray}}>
        {title}
      </Typography>
      <Divider sx={{my: 2}} />
      <Box sx={{display: "flex", flexWrap: "wrap", gap: 1}}>
        {selectedOptions.map((option) => (
          <Chip
            key={option}
            label={option}
            color="error"
            variant="outlined"
            onDelete={() => handleCheckboxChange(option)}
            sx={{
              height: "25px",
              "& .MuiChip-deleteIcon": {
                ":hover": {
                  color: color.text.secondary,
                },
              },
              ":hover": {
                color: color.text.secondary,
                backgroundColor: color.text.error,
                "& .MuiChip-deleteIcon": {
                  color: color.text.secondary,
                },
              },
            }}
          />
        ))}
      </Box>
      {options.map((option) => (
        <FormControlLabel
          sx={{display: "flex"}}
          key={option}
          control={
            <Checkbox
              checked={selectedOptions.includes(option)}
              onChange={() => handleCheckboxChange(option)}
              sx={{
                color: "#ccc",
                "&.Mui-checked": {
                  color: color.text.error,
                },
              }}
            />
          }
          label={
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                color: color.mutedGray,
                ":hover": {color: color.text.error},
              }}
            >
              {option}
              {showPercentage && (
                <Typography
                  component="span"
                  sx={{
                    display: "inline-flex",
                    backgroundImage: `url(${percentImg})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: 24,
                    height: 24,
                    color: color.text.secondary,
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 800,
                    fontSize: "0.8rem",
                    mx: 0.5,
                  }}
                >
                  %
                </Typography>
              )}
            </Typography>
          }
        />
      ))}
    </Box>
  );
};

export default Filter;
