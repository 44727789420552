import React from "react";
import {Box, Link, IconButton, Divider} from "@mui/material";
import Grid from "@mui/material/Grid2";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import CloseIcon from "@mui/icons-material/Close";

const offWhite = "#e5d6d6";
const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#D32F2F",
        color: "#FFFFFF",
        px: {xs: 0.5, sm: 2, lg: 4},
        py: {xs: 4, sm: 2, lg: 4},
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{maxWidth: "1400px", mx: "auto", width: "100%"}}
      >
        <Grid size={{xs: 3, sm: 4, md: 3}}>
          {/* Left Section: Country Links */}
          <Box
            sx={{
              display: {xs: "inline-flex", lg: "flex"},
              gap: "8px",
              fontSize: "12px",
            }}
          >
            <Link href="#" color="inherit" underline="none">
              Colombia
            </Link>
            <Link href="#" color="inherit" underline="none">
              Venezuela
            </Link>
            <Link href="#" color="inherit" underline="none">
              Guatemala
            </Link>
          </Box>
        </Grid>
        {/* Center Section: Other Links */}
        <Grid size={{xs: 0, sm: 4, md: 7}}>
          <Box
            sx={{
              display: {xs: "none", md: "flex"},
              gap: "8px",
              textAlign: "center",
              maxWidth: "60%",
              flexWrap: "wrap",
              justifyContent: "center",
              fontSize: "12px",
              mx: "auto",
            }}
          >
            <Link href="#" color="inherit" underline="none">
              Contact Us
            </Link>
            <Divider
              orientation="vertical"
              flexItem
              sx={{borderColor: offWhite}}
            />
            <Link href="#" color="inherit" underline="none">
              About Us
            </Link>
            <Divider
              orientation="vertical"
              flexItem
              sx={{borderColor: offWhite}}
            />
            <Link href="#" color="inherit" underline="none">
              Frequently Asked Questions
            </Link>

            <Link href="#" color="inherit" underline="none">
              Add Restaurant
            </Link>
            <Divider
              orientation="vertical"
              flexItem
              sx={{borderColor: offWhite}}
            />
            <Link href="#" color="inherit" underline="none">
              Points System
            </Link>
            <Divider
              orientation="vertical"
              flexItem
              sx={{borderColor: offWhite}}
            />
            <Link href="#" color="inherit" underline="none">
              Terms and Conditions
            </Link>
          </Box>
        </Grid>
        <Grid size={{xs: 8, sm: 4, md: 2}}>
          {/* Right Section: Social Media Icons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: {xs: "end", lg: "center"},
              gap: {xs: 0, sm: "8px"},
            }}
          >
            <IconButton color="inherit" sx={{bgcolor: "#fff", color: "#000"}}>
              <FacebookIcon />
            </IconButton>
            <IconButton color="inherit" sx={{bgcolor: "#fff", color: "#000"}}>
              <InstagramIcon />
            </IconButton>
            <IconButton color="inherit" sx={{bgcolor: "#fff", color: "#000"}}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
