import {
  Box,
  Typography,
  Button,
  IconButton,
  ButtonGroup,
  LinearProgress,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import {COLORS as color} from "constants/Colors";

const DishesRating = ({dish, maxVotes, index, handleVote}) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "space-between",
        mb: 1,
        width: "93%",
        borderBottom: 1,
        borderColor: color.border.info,
        pb: 1,
      }}
    >
      <Box sx={{width: "100%", display: "flex"}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography sx={{color: color.lightGray3}}>{dish.name}</Typography>
          <LinearProgress
            variant="determinate"
            value={dish.isVoted && (dish.votes / maxVotes) * 100}
            color="inherit"
            name={dish.name}
            sx={{
              bgcolor: color.offWhite,
              width: "98%",
              height: 10,
              borderRadius: 2,
              mr: 2,
              "& .MuiLinearProgress-bar1": {
                backgroundColor: color.lightGray3,
              },
            }}
          />
        </Box>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <Button
            sx={{
              px: 1,
              borderColor: color.badge.secondary,
              backgroundColor: "#dfe8f8",
            }}
          >
            <IconButton sx={{p: 0}} onClick={() => handleVote(index)}>
              {dish.isVoted ? <ThumbUpIcon /> : <ThumbUpAltOutlinedIcon />}
            </IconButton>
          </Button>
          <Button
            sx={{
              px: 1,
              borderColor: color.badge.secondary,
              backgroundColor: color.badge.secondary,
              color: color.text.textPrimary,
              fontWeight: 600,
            }}
          >
            {dish.votes}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default DishesRating;
