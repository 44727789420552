import React from "react";
import {Select, MenuItem, InputAdornment} from "@mui/material";
import {styled} from "@mui/system";
import {COLORS as color} from "constants/Colors";

const CustomSelect = styled(Select)(({theme}) => ({
  display: "flex",
  alignItems: "center",
  color: "black",
  "& .MuiSelect-select": {
    padding: theme.spacing(0.9),
    display: "flex",
    alignItems: "center",
    outline: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const SelectField = ({value, handleChange, icon, options, style, ...props}) => {
  return (
    <CustomSelect
      value={value}
      defaultValue={value}
      onChange={handleChange}
      displayEmpty
      startAdornment={
        icon && <InputAdornment position="start">{icon}</InputAdornment>
      }
      {...props}
      variant="standard"
      disableUnderline
      inputProps={{"aria-label": "Without label"}}
      sx={{
        ...style,
        "& .MuiInput-input": {
          color: color.text.info,
        },
      }}
    >
      {options?.map((option) => (
        <MenuItem key={option?.value} value={option?.value}>
          {option?.label}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default SelectField;
