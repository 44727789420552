import React, {useRef} from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import {Autoplay, Pagination} from "swiper/modules";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css/pagination";

import pizzaImg from "assets/images/pizza.jpg";
import pizzaImg1 from "assets/images/homeBg.jpg";
import coffee1Img from "assets/images/coffee1.jpg";
import coffee2Img from "assets/images/coffee2.jpg";

import {COLORS as color} from "constants/Colors";
import "./styles.css";

const buttonStyles = {
  borderColor: color.border.info,
  color: color.text.primary,
  textTransform: "capitalize",
  my: 0.4,
};
const data = [
  {
    id: 1,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: coffee1Img,
    rank: "4.0",
  },
  {
    id: 2,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: coffee2Img,
    rank: "4.0",
  },
  {
    id: 3,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg,
    rank: "4.0",
  },
  {
    id: 4,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg1,
    rank: "4.0",
  },
  {
    id: 5,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg,
    rank: "4.0",
  },
  {
    id: 6,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg,
    rank: "4.0",
  },
  {
    id: 7,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg1,
    rank: "4.0",
  },
  {
    id: 8,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg,
    rank: "4.0",
  },
];
const RestaurantInfo = () => {
  const swiperRef = useRef(null);

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: 1,
        boxShadow: 0,
        border: `1px solid ${color.border.info}`,
      }}
    >
      {/* Carousel Navigation */}
      <Box
        sx={{
          position: "relative",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "50%",
            borderRadius: 0,
            left: 1,
            zIndex: 2,
            transform: "translateY(-50%)",
            backgroundColor: color.overlay.primary,
            color: color.text.secondary,
          }}
          onClick={handlePrevSlide}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Swiper
          modules={[Autoplay, Pagination]}
          ref={swiperRef}
          spaceBetween={8}
          slidesPerView={1}
          slidesPerGroup={1}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          loop={false}
          pagination={{clickable: true}}
          slideToClickedSlide={true}
          className="mySwiper"
        >
          {data?.map((item, index) => (
            <SwiperSlide>
              <CardMedia
                component="img"
                image={item.cover}
                alt="Italian dish"
                maxWidth="100%"
                sx={{height: {xs: 200, sm: 300}}}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <IconButton
          sx={{
            position: "absolute",
            top: "50%",
            borderRadius: 0,
            right: 1,
            zIndex: 2,
            transform: "translateY(-50%)",
            backgroundColor: color.overlay.primary,
            color: color.text.secondary,
          }}
          onClick={handleNextSlide}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: color.border.info,
          py: 1,
        }}
      >
        <Box>
          <Typography variant="h6" sx={{color: color.text.textPrimary}}>
            Hello Ragazzi (Crowned)
          </Typography>
          <Typography variant="body2" sx={{color: color.lightGray3}}>
            Italian
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            sx={{color: color.blue, fontWeight: "bold", textAlign: "right"}}
          >
            $23
          </Typography>
          <Button
            startIcon={<ReplyOutlinedIcon sx={{transform: "rotate(143deg)"}} />}
            variant="outlined"
            size="small"
            style={{opacity: 0.8}}
            sx={buttonStyles}
          >
            Share
          </Button>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          px: 2,
          gap: "0.1rem",
        }}
      >
        <Button
          startIcon={<CallOutlinedIcon sx={{color: color.greenCane}} />}
          variant="outlined"
          size="medium"
          sx={buttonStyles}
        >
          See phone
        </Button>
        <Button
          startIcon={<SmsOutlinedIcon />}
          variant="outlined"
          size="medium"
          sx={buttonStyles}
        >
          Qualify
        </Button>
        <Button
          startIcon={<BookmarkBorderIcon />}
          variant="outlined"
          size="medium"
          sx={buttonStyles}
        >
          Keep
        </Button>
        <Button
          startIcon={<CheckCircleOutlineIcon />}
          variant="outlined"
          size="medium"
          sx={buttonStyles}
        >
          Visited
        </Button>
      </CardActions>
    </Card>
  );
};

export default RestaurantInfo;
