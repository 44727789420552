import Grid from "@mui/material/Grid2";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Rating,
} from "@mui/material";
import {Link} from "react-router-dom";

import {COLORS as color} from "constants/Colors";

import coffee2 from "assets/images/coffee2.jpg";
import pizza from "assets/images/pizza.jpg";
import homeBg from "assets/images/homeBg.jpg";

const ratingStyles = {
  "& .MuiRating-iconFilled": {
    color: color.scarletRed,
  },
  ml: 1,
};

const Reviews = () => {
  return (
    <Card
      sx={{
        boxShadow: 0,
      }}
    >
      <CardContent
        sx={{
          px: 1.5,
          "&.MuiCardContent-root:last-child": {
            pb: 1,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Box sx={{display: "flex", gap: 1.5}}>
              <Link to="/interior/restaurant/2">
                <CardMedia
                  component="img"
                  image={coffee2}
                  alt="Dish 1"
                  sx={{width: 60, height: 60, borderRadius: 2}}
                />
              </Link>
              <Box>
                <Link
                  to="/interior/restaurant/2"
                  style={{
                    textDecoration: "none",
                    color: color.text.textPrimary,
                  }}
                >
                  {" "}
                  <Typography variant="h6">Micaela Restaurant</Typography>{" "}
                </Link>
                <Typography variant="subtitle2" color="text.secondary">
                  Pedasi
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body2"
              sx={{marginTop: 2, color: color.text.primary}}
            >
              Excellent service, very nice restaurant with a view of the sea
              and/or pool. I ordered a blueberry smoothie and lemon pie to cool
              off.
            </Typography>
          </Box>
          <Typography variant="body2" sx={{color: color.text.primary}}>
            03/30/2024
          </Typography>
        </Box>

        {/* Ratings */}
        <Grid container spacing={2} sx={{marginTop: 2}}>
          <Grid size={{xs: 12, sm: 4}} sx={{textAlign: "center"}}>
            <Typography variant="body2" sx={{color: color.text.primary}}>
              Meal
            </Typography>
            <Rating value={5} readOnly sx={ratingStyles} />
          </Grid>
          <Grid size={{xs: 12, sm: 4}} sx={{textAlign: "center"}}>
            <Typography variant="body2" sx={{color: color.text.primary}}>
              Service
            </Typography>
            <Rating value={5} readOnly sx={ratingStyles} />
          </Grid>
          <Grid size={{xs: 12, sm: 4}} sx={{textAlign: "center"}}>
            <Typography variant="body2" sx={{color: color.text.primary}}>
              Atmosphere
            </Typography>
            <Rating value={5} readOnly sx={ratingStyles} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* Images */}
          <Grid size={{xs: 12, sm: 4}} sx={{textAlign: "center"}}>
            <Box sx={{display: "flex", gap: 2, marginTop: 2}}>
              <CardMedia
                component="img"
                image={pizza}
                alt="Dish 1"
                sx={{width: 100, height: 100, borderRadius: 2}}
              />
              <CardMedia
                component="img"
                image={homeBg}
                alt="Dish 2"
                sx={{width: 100, height: 100, borderRadius: 2}}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Reviews;
