import Grid from "@mui/material/Grid2";
import {Box} from "@mui/material";

import Header from "pages/Home/components/Header";
import Tabs from "pages/Home/components/Tabs";
import FeatureRestaurant from "pages/Home/components/FeatureRestaurant";
import Popular from "pages/Home/components/Popular";
import ForCarnivores from "pages/Home/components/ForCarnivores";
import NewInterior from "pages/Home/components/NewInterior";
import Collections from "pages/Home/components/Collections";
import AppPromotion from "pages/Home/components/AppPromotion";

import {CONSTANTS as c} from "constants/AppConstants";

import pizzaImg from "assets/images/pizza.jpg";
import pizzaImg1 from "assets/images/homeBg.jpg";
import coffee1Img from "assets/images/coffee1.jpg";
import coffee2Img from "assets/images/coffee2.jpg";

const data = [
  {
    id: 1,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: coffee1Img,
    rank: "4.0",
  },
  {
    id: 2,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: coffee2Img,
    rank: "4.0",
  },
  {
    id: 3,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg,
    rank: "4.0",
  },
  {
    id: 4,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg1,
    rank: "4.0",
  },
  {
    id: 5,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg,
    rank: "4.0",
  },
  {
    id: 6,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg,
    rank: "4.0",
  },
  {
    id: 7,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg1,
    rank: "4.0",
  },
  {
    id: 8,
    title: " Casa Congo",
    menu: "Caribeña, Panameña",
    location: "Colón",
    cover: pizzaImg,
    rank: "4.0",
  },
];

const tabStyles = {
  minHeight: {xs: "100vh", sm: "50vh", md: "10vh"},
  height: "100%",
  py: 2,
  display: "flex",
  justifyContent: "center",
  position: "relative",
};

const contentStyles = {maxWidth: c.MAX_WIDTH, mx: "auto", width: "90%"};

const Home = () => {
  return (
    <Grid container spacing={6} sx={{bgcolor: "background.paper"}}>
      <Grid size={{xs: 12}}>
        <Header />
      </Grid>
      <Grid size={{xs: 12}}>
        <Box sx={tabStyles}>
          <Tabs />
        </Box>
      </Grid>
      <Grid size={{xs: 12}}>
        <Box maxWidth={c.MAX_WIDTH} margin="0 auto">
          <FeatureRestaurant data={data} />
        </Box>
      </Grid>
      <Grid size={{xs: 12}}>
        <Box sx={contentStyles}>
          <Popular data={data} />
        </Box>
      </Grid>
      <Grid size={{xs: 12}}>
        <Box sx={contentStyles}>
          <ForCarnivores data={data} />
        </Box>
      </Grid>
      <Grid size={{xs: 12}}>
        <Box sx={contentStyles}>
          <NewInterior data={data} />
        </Box>
      </Grid>
      <Grid size={{xs: 12}}>
        <Box sx={contentStyles}>
          <Collections data={data} />
        </Box>
      </Grid>
      <Grid size={{xs: 12}}>
        <Box margin="0 auto">
          <AppPromotion />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Home;
